import "./PayseraAccept.css";
import {usePayseraAccept} from "./usePayseraAccept";
import {Link} from "react-router-dom";
import {ContentLoader} from "../../../../global/display-components/content-loader/ContentLoader";
import React from "react";
import {useTranslation} from "react-i18next";
import {CanceledIcon, ReceivedIcon} from "../../../../global/icons";
import {ROUTER_HOME} from "../../../../global/Constants";
import {Warning} from "../../../components/content/Warning";

export const PayseraAccept = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const data = searchParams.get('data') ?? "";
    const ss1 = searchParams.get('ss1') ?? "";
    const ss2 = searchParams.get('ss2') ?? "";
    const {t} = useTranslation();
    const {acceptResponse, success, redirectTimer} = usePayseraAccept(data, ss1, ss2);

    if (!acceptResponse) {
        return <ContentLoader style={{height: "400px"}}/>
    }

    if (!acceptResponse.success) {
        return <div className={"paysera-accept-container"}>
            <div className={"error"}>{t("unexpected_error")}</div>
        </div>
    }

    return <div className={"paysera-accept-container"}>
        <div className={"big-title no-margin"}>
            {t("order_id")}: {acceptResponse.orderId}
        </div>
        <div className={"title"}>
            {success !== true && t("waiting_for_payment")}
            {success === true && t("payment_received")!}
        </div>
        {success === undefined && <svg width="150px" height="150px" viewBox="0 0 42 42" className="donut"
                                       style={{marginBottom: "40px", marginTop: "10px"}}>
            <circle className={"timer-circle"} id="c1" cx="21" cy="21" r="15.91549430918954" strokeDasharray="100 0"
                    strokeDashoffset="100"></circle>
            <circle className={"timer-circle"} id="c2" cx="21" cy="21" r="15.91549430918954" strokeDasharray="0 100"
                    strokeDashoffset="0"></circle>
            <g className="chart-text">
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" id="counterText" fill="white">5
                </text>
            </g>
        </svg>}
        {success === true &&
            <ReceivedIcon style={{fontSize: "80px", color: "var(--lime)", padding: "20px 0 20px 0", marginBottom: " 40px"}}/>}
        {success === false &&
            <React.Fragment>
                <CanceledIcon style={{fontSize: "80px", color: "red", padding: "20px 0 20px 0", marginBottom: " 30px"}}/>
                <div className={"error"} style={{marginBottom:"40px"}}>
                    {t("unexpected_error")}
                </div>
            </React.Fragment>
        }
        {success === undefined && <div className={"text no-margin"}>{t("please_do_not_turn_off_this_window")}</div>}
        {success !== undefined && <React.Fragment>
            <div
                className={"text no-margin"}>{t("you_will_be_redirected_to_home_page_in_0_sec", {0: redirectTimer})}</div>
            <div className={"title no-margin"}>{"or"}</div>
            <div className={"text no-margin"}>{t("click_here")}: <Link to={`/${ROUTER_HOME}`}>{t("home")}</Link></div>
        </React.Fragment>
        }
    </div>
}