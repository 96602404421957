import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faArrowRotateBack,
    faBars,
    faCheckToSlot,
    faCircleCheck,
    faCircleQuestion,
    faExclamation,
    faGamepad,
    faHand,
    faHeart,
    faHouse,
    faLanguage,
    faListCheck,
    faPaperPlane,
    faPlus,
    faRectangleList,
    faRightToBracket,
    faScroll,
    faShop,
    faSignsPost,
    faThumbsUp as solidThumbsUp,
    faThumbsDown as solidThumbsDown,
    faXmark,
    faComputerMouse,
    faX
} from '@fortawesome/free-solid-svg-icons';
import {faDiscord} from '@fortawesome/free-brands-svg-icons'
import {faCommentDots, faEye, faThumbsDown, faThumbsUp,
    faCircleXmark,
    faCircleCheck as faCircleCheckLine} from "@fortawesome/free-regular-svg-icons";

interface IconProps {
    style?: React.CSSProperties;
    className?: string;
}

export const HomeIcon: React.FC<IconProps> = ({style}) => {
    return <FontAwesomeIcon icon={faHouse} style={style}/>
};

export const ShopIcon: React.FC<IconProps> = ({style}) => {
    return <FontAwesomeIcon icon={faShop} style={style}/>
};

export const AboutIcon: React.FC<IconProps> = ({style}) => {
    return <FontAwesomeIcon icon={faCircleQuestion} style={style}/>
};

export const GamepadIcon: React.FC<IconProps> = ({style}) => {
    return <FontAwesomeIcon icon={faGamepad} style={style}/>
};

export const GamepadShakeIcon: React.FC<IconProps> = ({style}) => {
    return <FontAwesomeIcon icon={faGamepad} style={style} shake/>
};

export const DiscordIcon: React.FC<IconProps> = ({style}) => {
    return <FontAwesomeIcon icon={faDiscord} style={style}/>
};

export const DiscordShakeIcon: React.FC<IconProps> = ({style}) => {
    return <FontAwesomeIcon icon={faDiscord} style={style} shake/>
};

export const VoteIcon: React.FC<IconProps> = ({style}) => {
    return <FontAwesomeIcon icon={faCheckToSlot} style={style}/>
};

export const CloseIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faXmark} style={props.style} className={props.className} />
};

export const CanceledIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faCircleXmark} style={props.style} className={props.className} beat />
};

export const ReceivedIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faCircleCheckLine} style={props.style} className={props.className} beat />
};

export const BouncingHeartIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faHeart} bounce style={props.style}/>
};

export const ScrollBounceIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faScroll} bounce style={props.style}/>
};

export const ExclamationBounceIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faExclamation} bounce style={props.style}/>
};

export const BarsIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faBars} style={props.style}/>
};

export const LoginIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faRightToBracket} style={props.style}/>
};

export const HandIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faHand} style={props.style}/>
};

export const SaveIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faCircleCheck} style={props.style}/>
};

export const ForumIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faRectangleList} style={props.style}/>
};

export const ForumRules: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faListCheck} style={props.style}/>
};

export const BackIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faArrowRotateBack} style={props.style}/>
};

export const AddIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faPlus} style={props.style}/>
};

export const LeftIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faAngleLeft} style={props.style}/>
};

export const RightIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faAngleRight} style={props.style}/>
};

export const LeftLeftIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faAnglesLeft} style={props.style}/>
};

export const RightRightIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faAnglesRight} style={props.style}/>
};

export const EyeIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faEye} style={props.style}/>
};

export const CommentsIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faCommentDots} style={props.style}/>
};

export const ThumbsUpIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={solidThumbsUp} style={props.style}/>
};

export const ThumbsDownIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={solidThumbsDown} style={props.style}/>
};

export const ThumbsUpUnactiveIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faThumbsUp} style={props.style}/>
};

export const ThumbsDownUnactiveIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faThumbsDown} style={props.style}/>
};


export const PostsIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faSignsPost} style={props.style}/>
};


export const SendIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faPaperPlane} style={props.style}/>
};

export const LogoutIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faRightToBracket} style={props.style} flip={"horizontal"}/>
};

export const LanguageIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faLanguage} style={props.style} flip={"horizontal"}/>
};

export const MouseClickIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faComputerMouse} style={props.style} beat />
};

export const XMarkIcon: React.FC<IconProps> = (props: IconProps) => {
    return <FontAwesomeIcon icon={faX} style={props.style} />
};