import {GlobalBuilder} from "../../../global/GlobalBuilder";
import {UserInfo} from "../../../models/data/UserInfo";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import {RequestUpdateUserData} from "../../../models/request/RequestUpdateUserData";

interface ExportProps {
    saveUserFirstTimeProfile: (selectedLanguage: string) => Promise<boolean>;
}

export const useFirstTimeProfileSetup = () : ExportProps  => {
    const userController = GlobalBuilder.getInstance().getUserController();
    const {setLoading, setUserInfo, userInfo,saveLanguagePreferenceToLocalStorage} = useGlobalContext();

    const saveUserFirstTimeProfile = async (selectedLanguage: string) => {
        try {
            setLoading(true);
            let model = new RequestUpdateUserData();
            model.setLanguagePreference(selectedLanguage);

            // Pass selectedLanguage and token directly to updateUserData
            let response = await userController.updateUserData(model, userInfo?.token ?? "");

            if (response) {
                removeFirstTime(selectedLanguage);
                saveLanguagePreferenceToLocalStorage(selectedLanguage);
                setLoading(false);
                return true;
            }
        } catch (error) {
            console.error('Error profile setup:', error);
        }

        setLoading(false);
        return false;
    }

    const removeFirstTime = (selectedLanguage: string) => {
        setUserInfo((prevUserInfo: any) => {
            if (prevUserInfo) {
                return {...prevUserInfo, firstTime: false, languagePreference: selectedLanguage} as UserInfo;
            }
            return prevUserInfo;
        });
    }


    return {
        saveUserFirstTimeProfile
    }
}