export class CreateMacroPaymentRequest {
    public purchasableItemId: number;
    public amountInCents: number;
    public email: string;

    constructor(
        purchasableItemId: number,
        amountInCents: number,
        email: string) {
        this.purchasableItemId = purchasableItemId;
        this.amountInCents = amountInCents;
        this.email = email;
    }
}