import './NewThread.css';
import {BUTTON_STYLE, StyledButton1} from "../../../components/button/Buttons";
import {AddIcon, BackIcon, ForumRules} from "../../../../global/icons";
import React, {useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Warning} from "../../../components/content/Warning";
import {useGlobalContext} from "../../../../global/context/GlobalContext";
import {useNewThread} from "./useNewThread";
import {BackButton} from "../../../components/button/BackButton";
import {ROUTER_FORUM, ROUTER_RULES} from "../../../../global/Constants";

export const NewThread = () => {
    const {id} = useParams();
    const parsedId = id ? parseInt(id) : NaN;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const {setLoading} = useGlobalContext();
    const acceptForumRulesRef = useRef<HTMLInputElement>(null);
    const acknowledgeConsequencesRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const {createThreadAsync} = useNewThread();

    if (isNaN(parsedId)) {
        navigate("/error");
        return null;
    }

    const createThread = async () => {
        setSuccess("");
        setError("");
        setLoading(true);
        if (fromIsValid()) {
            const acceptForumRules = acceptForumRulesRef.current?.checked;
            const acknowledgeConsequences = acknowledgeConsequencesRef.current?.checked;

            if (acceptForumRules && acknowledgeConsequences) {
                try {
                    var response = await createThreadAsync(parsedId, title, description);
                    if (response != null && response.success) {
                        setTitle("");
                        setDescription("");
                        setSuccess(t("thread_created"));
                        navigate(`../${ROUTER_FORUM}/${parsedId}/${response.threadId}`)
                    }
                    else if (response != null && response.cooldownInSeconds > 0){
                        setError(t("please_wait_0_seconds", {0: response?.cooldownInSeconds ?? 0}));
                    }
                    else{
                        setError(t("you_cannot_create"));
                    }
                } catch (error) {
                    setError(t("error"));
                }
            } else {
                // At least one checkbox is not checked, show an error message or handle it accordingly
                setError(t("accept_terms_and_conditions"));
                // You can display an error message to the user or handle the situation as needed
            }
        }
        else{
            if (title?.length > 70){
                setError(t("title_too_long"));
            }
            else if (description?.length > 500){
                setError(t("description_too_long"));
            }
            else{
                setError(t("title_and_description_required"));
            }
        }
        setLoading(false);
    };

    const fromIsValid = (): boolean => {
        return !!(title && description) && title?.length <= 70 && description.length <= 500;
    }

    const isFilled = (text: string | undefined) => {
        return text === undefined || text;
    }

    return <React.Fragment>
        <div className={"text-content"}>
            <BackButton />
            <Warning style={{marginTop: "40px"}}>
                <div className={'title'}>
                    {t("please_read_forum_rules_before_creating_a_thread")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_2} classes={"button-style-2-secondary"}  onClick={() => navigate(`../${ROUTER_FORUM}/${ROUTER_RULES}`)}>
                    <div className={"button-content"}>
                        {t('forum_rules')}
                        <ForumRules/>
                    </div>
                </StyledButton1>
            </Warning>
        </div>
        <div className={"background-title"}>
            {t("create")}:
        </div>
        <div className={"text-content"}>
            <div className={"big-title"}>
                <span className="emoji emoji-thread" role="img" aria-label="monkey"></span>
                {t("you_are_currently_creating_a_new_thread")}
            </div>
            <div className={"thread-form"}>
                {error &&
                <div className={"inputBox"}>
                    <div className="no-margin error">
                        {error}
                    </div>
                </div>}
                {success &&
                    <div className={"inputBox"}>
                        <div className="no-margin success">
                            {success}
                        </div>
                    </div>}
                <div className={`inputBox ${!isFilled(title) ? "display-input-error" : ""}`}
                     data-content={"Title required"}
                     data-length={`${title.length} / ${70}`}>
                    <input
                        type="text"
                        required
                        onChange={(e: any) => setTitle(e.target.value)}
                        value={title}
                    />
                    <i className="text no-margin bold">{t("title").toUpperCase()}</i>
                </div>
                <div className={`inputBox ${!isFilled(description) ? "display-input-error" : ""}`}
                     data-content={"Description required"}
                     data-length={`${description.length} / ${500}`}>
            <textarea
                required
                onChange={(e: any) => setDescription(e.target.value)}
                value={description}
            />
                    <i className="text no-margin bold">{t("description").toUpperCase()}</i>
                </div>
                <div style={{marginTop: "10px"}}>
                    <div className="checkbox-wrapper-4">
                        <input className="inp-cbx" id="accept-1" type="checkbox" ref={acceptForumRulesRef}/>
                        <label className="cbx" htmlFor="accept-1"><span>
  <svg width="12px" height="10px">
  </svg></span><span>{t("i_have_read_and_agree_to_forum_rules")}</span></label>
                        <svg className="inline-svg">
                            <symbol id="check-4" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </symbol>
                        </svg>
                    </div>
                    <div className="checkbox-wrapper-4">
                        <input className="inp-cbx" id="accept-2" type="checkbox" ref={acknowledgeConsequencesRef}/>
                        <label className="cbx" htmlFor="accept-2"><span>
  <svg width="12px" height="10px">
  </svg></span><span>{t("i_agree_to_consequences_breaking_forum_rules")}</span></label>
                        <svg className="inline-svg">
                            <symbol id="check-4" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </symbol>
                        </svg>
                    </div>
                </div>
                <div style={{marginLeft: "auto"}}>
                    <StyledButton1 style={BUTTON_STYLE.STYLE_2} classes={"button-style-2"}
                                   onClick={createThread}>
                        <div className={"button-content"}>
                            {t("create")}
                            <AddIcon/>
                        </div>
                    </StyledButton1>
                </div>
            </div>
        </div>
    </React.Fragment>
}