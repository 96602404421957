import React, {useEffect} from "react"
import './ShopCategory.css';
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {BackButton} from "../../components/button/BackButton";
import {useShopCategory} from "./useShopCategory";
import {AnimatedElementsOpacity} from "../../components/animation/AnimatedElementsOpacity";
import {ContentLoader} from "../../../global/display-components/content-loader/ContentLoader";
import {Warning} from "../../components/content/Warning";
import payseraLogo from "../../../resources/images/2_Paysera logo for dark background.svg";
import {AnimatedElementsFromBottom} from "../../components/animation/AnimatedElementsFromBottom";
import {DoubleCard} from "../../components/content/DoubleCard";
import {McBalticsImageWithFallback, ProfileImageWithFallback} from "../../components/utils/ProfileImageWithFallback";
import {MouseClickIcon, XMarkIcon} from "../../../global/icons";
import {DISCORD_INVITE_LINK} from "../../../global/Constants";
import {getPrice} from "../../../global/Utils";
import {ShopItemDescription} from "../shop-item-description/ShopItemDescription";

export const ShopCategory = () => {
    const {id} = useParams();
    const parsedId = id ? parseInt(id) : NaN;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {
        userInfo,
        shopCategoryWithItems,
        getItemWithDescription,
        setModalWindow,
        itemModalData,
        setItemModalData
    } = useShopCategory(parsedId);

    useEffect(() => {
        if (itemModalData !== null) {
            setModalWindow(<ShopItemDescription purchasableItem={itemModalData.purchasableItem}
                                                purchasableItemDescription={itemModalData.purchasableItemDescription}
                                                setItemModalData={setItemModalData}/>);
        }
        else{
            setModalWindow(null);
        }
    }, [itemModalData])

    if (isNaN(parsedId)) {
        navigate("/error");
        return null;
    }


    if (userInfo === undefined) {
        return <Warning style={{marginTop: "40px"}}>
            <div className={'title'}>{t("login_required")}:</div>
            <div className={'text'}>{t("you_must_login_to_use_this")}!</div>
        </Warning>
    }

    if (shopCategoryWithItems === null) {
        return <AnimatedElementsOpacity>
            <ContentLoader style={{height: "50vh", minHeight: "700px"}}/>
        </AnimatedElementsOpacity>
    }

    return <React.Fragment>
        <div className={"text-content"}>
            <BackButton/>
            <div className={"big-title no-margin"} style={{marginTop: "40px"}}>
                <span className="emoji emoji-shop" role="img" aria-label="monkey"></span>
                {t("buying")}:&nbsp;{t(shopCategoryWithItems.purchasableItemCategory.nameKey)}</div>
            <div className={"text"}>
                {t("payment_using_paysera_explanation")}
            </div>
            <div className={"title"}>
                {t("payment_using_paysera")}<img src={payseraLogo} alt={"flag"}
                                                 className={"shop-category-paysera-logo"}/>
            </div>
            <Warning>
                <div className={'title'}>{t("important_note")}:</div>
                <div className={'text'}>{t("paysera_payment_redirection_explanation")}</div>
            </Warning>
            <div className={"text no-margin"}>
                {t("payments_are_not_returnable")}
            </div>
        </div>
        <div className={"background-title"}>
            {t("choose")}:
        </div>
        <div className={"shop-category-items"}>
            {shopCategoryWithItems.purchasableItems.map(x =>
                <AnimatedElementsFromBottom>
                    <DoubleCard key={x.nameKey}
                                className={"double-card-shop-category"}
                                firstContent={
                                    <div className={"double-card-first-content"}>
                                        <div className={"shop-category-item-display"}>
                                            <McBalticsImageWithFallback src={x.base64Photo}
                                                                      alt="Profile"
                                                                      style={{
                                                                   width: '200px',
                                                                   height: "200px",
                                                                   objectFit: "cover",
                                                                   position: "relative",
                                                                   borderRadius: "5px",
                                                                   minHeight: '50px',
                                                                   minWidth: '50px'
                                                               }}/>
                                            <div style={{display:"flex", gap:"10px"}}>
                                                <div>{t(x.nameKey)}</div>
                                                {x.quantity &&
                                                    <div style={{display:"flex", gap:"2px",alignItems:"center", color:"var(--purple)", wordBreak:"initial"}}>
                                                        <b style={{fontSize:"20px"}}>x</b>{x.quantity}
                                                    </div>}
                                            </div>
                                            <div>
                                                {getPrice(x.priceInCents, x.priceMultiplier)}
                                            </div>
                                        </div>
                                    </div>
                                }
                                secondContent={<div className="double-card-second-content">
                                    <div className={"double-card-second-content-shop-category-container"}>
                                        <div className={"shop-grid-card-title-secondary"}>
                                            {t(x.nameKey)}
                                        </div>
                                        <div>
                                            {t("view_info_and_buy")}
                                        </div>
                                        <div className="shop-grid-card-extra-text">
                                            <MouseClickIcon/>{t(`click_for_more`)}<MouseClickIcon/>
                                        </div>
                                    </div>
                                </div>}
                                onClick={() => getItemWithDescription(x.id)}/>
                </AnimatedElementsFromBottom>
            )}
        </div>
        <div className={"text-content"}>
            <div className={"big-title no-margin"}>
                {t("payment_issues")}?
            </div>
            <div className={"text"}>
                {t("payment_issues_1")}
            </div>
            <div className={"title"}>
                {t("join_our_discord_server")}: <a href={DISCORD_INVITE_LINK}>{DISCORD_INVITE_LINK}</a>
            </div>
        </div>
    </React.Fragment>
}