import React from 'react';
import {useTranslation} from 'react-i18next';
import FlagLt from '../../../resources/images/Flag_lt.svg';
import FlagLv from '../../../resources/images/Flag_lv.svg';
import FlagEe from '../../../resources/images/Flag_ee.svg';
import FlagUk from '../../../resources/images/Flag_uk.svg';
import './LanguageSwitcher.css';
import {useGlobalContext} from "../../../global/context/GlobalContext";
import {CloseIcon} from "../../../global/icons";

export interface Props {
    mobile?: boolean |undefined;
}

const LanguageSwitcher = (props: Props) => {
    const {mobile = false} = props;
    const {t} = useTranslation();
    const {showTranslations, setShowTranslations, selectedLanguage, changeLanguage} = useGlobalContext();

    const RenderPcLanguageSwitcher = () => <div
        className={'language-switcher ' + (showTranslations ? 'language-switcher-display' : '')}>
        <div className={'language-switcher-contents'}>
            <div>
                {t('select_language')}:
            </div>
            <div className={'language-switcher-flags'}>
                <img src={FlagLt} alt="Flag of Lithuania" onClick={() => changeLanguage('lt')}
                     className={'flag ' + (selectedLanguage === 'lt' ? 'active-flag' : '')}/>
                <img src={FlagLv} alt="Flag of Latvia" onClick={() => changeLanguage('lv')}
                     className={'flag ' + (selectedLanguage === 'lv' ? 'active-flag' : '')}/>
                <img src={FlagEe} alt="Flag of Estonia" onClick={() => changeLanguage('ee')}
                     className={'flag ' + (selectedLanguage === 'ee' ? 'active-flag' : '')}/>
                <img src={FlagUk} alt="Flag of United Kingdom" onClick={() => changeLanguage('en')}
                     className={'flag ' + (selectedLanguage === 'en' ? 'active-flag' : '')}/>
            </div>
        </div>
        <div className={'language-switcher-close-button'} onClick={() => setShowTranslations(!showTranslations)}>
            {t('close').toUpperCase()}
            <CloseIcon style={{fontSize: '22px', marginLeft: '10px'}}/>
        </div>
    </div>

    const RenderMobileLanguageSwitcher = () => <div className={'language-switcher-flags'}>
        <img src={FlagLt} alt="Flag of Lithuania" onClick={() => changeLanguage('lt')}
             className={'flag mobile ' + (selectedLanguage === 'lt' ? 'active-flag' : '')}/>
        <img src={FlagLv} alt="Flag of Latvia" onClick={() => changeLanguage('lv')}
             className={'flag mobile ' + (selectedLanguage === 'lv' ? 'active-flag' : '')}/>
        <img src={FlagEe} alt="Flag of Estonia" onClick={() => changeLanguage('ee')}
             className={'flag mobile ' + (selectedLanguage === 'ee' ? 'active-flag' : '')}/>
        <img src={FlagUk} alt="Flag of United Kingdom" onClick={() => changeLanguage('en')}
             className={'flag mobile ' + (selectedLanguage === 'en' ? 'active-flag' : '')}/>
    </div>

    return mobile ?
        RenderMobileLanguageSwitcher()
        :
        RenderPcLanguageSwitcher();
};

export default LanguageSwitcher;
