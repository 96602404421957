import {Background} from "../../../components/background/Background";
import {Content} from "../../../components/content/Content";
import {NavigationBar} from "../../../components/navigation-bar/NavigationBar";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {
    ROUTER_ABOUT, ROUTER_ACCEPT, ROUTER_CANCEL,
    ROUTER_FORUM,
    ROUTER_HOME,
    ROUTER_LOGIN, ROUTER_NEW,
    ROUTER_PARAM_ID, ROUTER_PARAM_ID_2, ROUTER_PASYERA, ROUTER_PROFILE, ROUTER_RULES, ROUTER_SHOP,
    ROUTER_VOTE, ROUTER_WIKI
} from "../../../../global/Constants";
import {Home} from "../../home/Home";
import {Vote} from "../../vote/Vote";
import {About} from "../../about/About";
import {Login} from "../../login/Login";
import {NotFound} from "../../error/NotFound";
import {BottomBar} from "../../../components/bottom-bar/BottomBar";
import React from "react";
import {PageLoader} from "../../../../global/display-components/page-loader/PageLoader";
import {useGlobalContext} from "../../../../global/context/GlobalContext";
import {FirstTimeProfileSetup} from "../../../components/first-time-profile-setup/FirstTimeProfileSetup";
import {MobileNavigationContent} from "../../MobileNavigationContent/MobileNavigationContent";
import {ForumCategories} from "../../forum/forum-categories/ForumCategories";
import {ForumThreads} from "../../forum/forum-threads/ForumThreads";
import {NewThread} from "../../forum/new-thread/NewThread";
import {Thread} from "../../forum/thread/Thread";
import {Profile} from "../../profile/Profile";
import {Wiki} from "../../wiki/Wiki";
import {ForumRules} from "../../forum/forum-rules/ForumRules";
import {ScrollToTopOnNavigate} from "../../../../global/Utils";
import {Shop} from "../../shop/Shop";
import {ShopCategory} from "../../shop-category/ShopCategory";
import {ModalWindow} from "../../../components/content/ModalWindow";
import {PayseraCancel} from "../../paysera/paysera-cancel/PayseraCancel";
import {PayseraAccept} from "../../paysera/paysera-accept/PayseraAccept";

export const AppContent = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === `/${ROUTER_LOGIN}`;
    const isPayseraPage = location.pathname.toLowerCase().includes(ROUTER_PASYERA);
    const isSpecialPage= isLoginPage || isPayseraPage;
    const {loading, userInfo, toggledMobile, modalWindow} = useGlobalContext();

    return <React.Fragment>
        <ScrollToTopOnNavigate />
        <div style={{height: '100vh', overflowY: 'auto'}}>
            {loading && <PageLoader/>}
            {toggledMobile && <MobileNavigationContent/>}
            {modalWindow && <ModalWindow children={modalWindow}/>}
            {userInfo?.firstTime && <FirstTimeProfileSetup/>}
            <Background isSpecialPage={isSpecialPage}>
                <div style={{position: "absolute", top: "0"}} id={"navigater-main"}></div>
                <Content>
                    {!isSpecialPage && <NavigationBar/>}
                    <Routes>
                        <Route
                            path="/"
                            element={<Navigate to={`/${ROUTER_HOME}`}/>}
                        />
                        <Route path={`/${ROUTER_HOME}`} element={<Home/>}/>
                        <Route path={`/${ROUTER_VOTE}`} element={<Vote/>}/>
                        <Route path={`/${ROUTER_ABOUT}`} element={<About/>}/>
                        <Route path={`/${ROUTER_LOGIN}`} element={<Login/>}/>
                        <Route path={`/${ROUTER_FORUM}`} element={<ForumCategories/>}/>
                        <Route path={`/${ROUTER_SHOP}`} element={<Shop/>}/>
                        <Route path={`/${ROUTER_SHOP}/${ROUTER_PARAM_ID}`} element={<ShopCategory/>}/>
                        <Route path={`/${ROUTER_FORUM}/${ROUTER_PARAM_ID}`} element={<ForumThreads />} />
                        <Route path={`/${ROUTER_FORUM}/${ROUTER_PARAM_ID}/${ROUTER_NEW}`} element={<NewThread />} />
                        <Route path={`/${ROUTER_FORUM}/${ROUTER_PARAM_ID}/${ROUTER_PARAM_ID_2}`} element={<Thread />} />
                        <Route path={`/${ROUTER_PROFILE}/${ROUTER_PARAM_ID}`} element={<Profile/>}/>
                        <Route path={`/${ROUTER_WIKI}`} element={<Wiki/>}/>
                        <Route path={`/${ROUTER_FORUM}/${ROUTER_RULES}`} element={<ForumRules/>}/>
                        <Route path={`/${ROUTER_PASYERA}/${ROUTER_CANCEL}`} element={<PayseraCancel/>}/>
                        <Route path={`/${ROUTER_PASYERA}/${ROUTER_ACCEPT}`} element={<PayseraAccept/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </Content>
            </Background>
            <BottomBar/>
        </div>
    </React.Fragment>
}