import './Shop.css';
import {Warning} from "../../components/content/Warning";
import React from "react";
import {useTranslation} from "react-i18next";
import {DoubleCard} from "../../components/content/DoubleCard";
import {useShop} from "./useShop";
import {ContentLoader} from "../../../global/display-components/content-loader/ContentLoader";
import {McBalticsImageWithFallback} from "../../components/utils/ProfileImageWithFallback";
import {MouseClickIcon} from "../../../global/icons";
import {Link} from "react-router-dom";
import {AnimatedElementsOpacity} from "../../components/animation/AnimatedElementsOpacity";
import {AnimatedElementsFromBottom} from "../../components/animation/AnimatedElementsFromBottom";

export const Shop = () => {
    const {t} = useTranslation();
    const {userInfo, shopCategories} = useShop();

    if (userInfo === undefined) {
        return <Warning style={{marginTop: "40px"}}>
            <div className={'title'}>{t("login_required")}:</div>
            <div className={'text'}>{t("you_must_login_to_use_this")}!</div>
        </Warning>
    }

    return <React.Fragment>
        <AnimatedElementsOpacity>
            <div className={"text-content"}>

                <div className={"big-title no-margin"}>
                    <span className="emoji emoji-shop" role="img" aria-label="monkey"></span>
                    {t("store")}</div>
                <div className={"title"}>
                    {t("welcome_to_our_store")}
                </div>
                <div className={"text"}>
                    {t("store_explanation_1")}
                </div>
            </div>
        </AnimatedElementsOpacity>
        {shopCategories === null ?
            <AnimatedElementsOpacity>
                <ContentLoader style={{height: "50vh", minHeight: "700px"}}/>
            </AnimatedElementsOpacity>
            :
            <React.Fragment>
                <AnimatedElementsOpacity>
                    <div className={"background-title"}>
                        {t("buy")}:
                    </div>
                </AnimatedElementsOpacity>
                <div className={"shop-grid"}>
                    {shopCategories.map((x, key) =>
                        <AnimatedElementsFromBottom key={key}>
                            <Link to={`${x.id}`}>
                                <DoubleCard key={x.nameKey}
                                            firstContent={
                                                <div className="double-card-first-content"
                                                     style={{position: 'relative'}}>
                                                    <div className={"shop-grid-card-title"}>{t(x.nameKey)}</div>
                                                    <McBalticsImageWithFallback src={x.base64Photo}
                                                                                alt="Profile"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    height: "100%",
                                                                                    objectFit: "cover",
                                                                                    position: "relative",
                                                                                    filter: "blur(3px)"
                                                                                }}/>
                                                </div>
                                            }
                                            secondContent={
                                                <div className="double-card-second-content">
                                                    <div className={"double-card-second-content-shop-container"}>
                                                        <div className={"shop-grid-card-title-secondary"}>
                                                            {t(x.nameKey)}
                                                        </div>
                                                        <div>
                                                            {t(`${x.nameKey}_explanation`)}
                                                        </div>
                                                        <div className="shop-grid-card-extra-text">
                                                            <MouseClickIcon/>{t(`click_for_more`)}<MouseClickIcon/>
                                                        </div>
                                                    </div>
                                                </div>}/>
                            </Link>
                        </AnimatedElementsFromBottom>
                    )}
                </div>
            </React.Fragment>
        }
    </React.Fragment>
}