import {AjaxHelper} from "../helpers/AjaxHelper";
import {API_URL} from "../global/Constants";
import {PurchasableItemCategory} from "../models/data/PurchasableItemCategory";
import {ResponseGetShopCategoryWithItems} from "../models/response/ResponseGetShopCategoryWithItems";
import {ResponseGetShopItemWithDescription} from "../models/response/ResponseGetShopItemWithDescription";
import {CreateMacroPaymentRequest} from "../models/request/CreateMacroPaymentRequest";
import {CreateMacroPaymentResponse} from "../models/response/CreateMacroPaymentResponse";
import {PreparePaymentResponse} from "../models/response/PreparePaymentResponse";

export class ShopController{
    private readonly ajaxHelper: AjaxHelper;

    constructor(ajaxHelper: AjaxHelper) {
        this.ajaxHelper = ajaxHelper;
    }

    async getAllShopCategories(token: string): Promise<PurchasableItemCategory[]| null> {
        try {
            const url = `${API_URL}/api/Shop/get_shop_categories`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };

            return await this.ajaxHelper.ajaxGet<PurchasableItemCategory[]>(url, headers);
        } catch (error: any) {
            console.error(`Error in getAllShopCategories: ${error.message}`);
        }
        return null;
    }

    async getAllCategoryItems(categoryId:number, token: string): Promise<ResponseGetShopCategoryWithItems | null> {
        try {
            const url = `${API_URL}/api/Shop/get_shop_categories_with_items?categoryId=${categoryId}`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };

            let result = await this.ajaxHelper.ajaxGet<ResponseGetShopCategoryWithItems>(url, headers);
            if (result == null || result.purchasableItemCategory == null || result.purchasableItems.length === 0){
                return null;
            }
            return  result;
        } catch (error: any) {
            console.error(`Error in getAllShopCategories: ${error.message}`);
        }
        return null;
    }

    async getItemWithDescription(itemId:number, token: string): Promise<ResponseGetShopItemWithDescription | null> {
        try {
            const url = `${API_URL}/api/Shop/get_shop_item_with_description?itemId=${itemId}`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };

            let result = await this.ajaxHelper.ajaxGet<ResponseGetShopItemWithDescription>(url, headers);
            if (result == null || result.purchasableItem == null){
                return null;
            }
            return  result;
        } catch (error: any) {
            console.error(`Error in getAllShopCategories: ${error.message}`);
        }
        return null;
    }

    async createPayseraPaymentRequest(createMacroPaymentRequest: CreateMacroPaymentRequest, token: string): Promise<CreateMacroPaymentResponse | null> {
        try {
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };
            const url = `${API_URL}/api/Paysera/create_macro_payment`;
            return await this.ajaxHelper.ajaxPost<CreateMacroPaymentResponse>(url, createMacroPaymentRequest, headers)
        } catch (error : any) {
            console.error(`Error in createPayseraPaymentRequest: ${error.message}`);
        }
        return null;
    }

    async getAccept(data:string, ss1:string, ss2: string, token: string): Promise<PreparePaymentResponse| null> {
        try {
            const url = `${API_URL}/api/Paysera/accept?data=${data}&ss1=${ss1}&ss2=${ss2}`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };

            return await this.ajaxHelper.ajaxGet<PreparePaymentResponse>(url, headers);
        } catch (error: any) {
            console.error(`Error in getAccept: ${error.message}`);
        }
        return null;
    }

    async getOrderCompletion(orderId: number, token: string): Promise<boolean| null> {
        try {
            const url = `${API_URL}/api/Paysera/get_order_completion?orderId=${orderId}`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };

            return await this.ajaxHelper.ajaxGet<boolean>(url, headers);
        } catch (error: any) {
            console.error(`Error in getOrderCompletion: ${error.message}`);
        }
        return null;
    }
}