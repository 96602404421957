import {useGlobalContext} from "../../../global/context/GlobalContext";
import "./FirstTimeProfileSetup.css";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import FlagLt from "../../../resources/images/Flag_lt.svg";
import FlagLv from "../../../resources/images/Flag_lv.svg";
import FlagEe from "../../../resources/images/Flag_ee.svg";
import FlagUk from "../../../resources/images/Flag_uk.svg";
import {BUTTON_STYLE, StyledButton1} from "../button/Buttons";
import {SaveIcon} from "../../../global/icons";
import {useFirstTimeProfileSetup} from "./useFirstTimeProfileSetup";

export const FirstTimeProfileSetup = () => {
    const {changeLanguage, selectedLanguage} = useGlobalContext();
    const {t} = useTranslation()
    const {saveUserFirstTimeProfile} = useFirstTimeProfileSetup();
    const [error, setError] = useState<string | undefined>(undefined);

    const saveSelectedLanguage = async () => {
        let saveResult = await saveUserFirstTimeProfile(selectedLanguage);
        if (!saveResult) {
            setError(t("failed_to_save"));
        }
    }

    return <div className={"first-time-user-profile-wrapper"}>
        <div className={"first-time-user-profile-background"}>
            <div className={"first-time-user-profile-background-wrapper"}>
                <div className={"first-time-user-profile-form"}>
                    <div className="big-title no-margin"
                         style={{textAlign: "center"}}>{t('select_preferred_language').toUpperCase()}</div>
                    {error &&
                        <div className="no-margin error " style={{width: "calc(100% - 40px)"}}>
                            {error}
                        </div>}
                    <div className={'first-time-user-profile-language-switcher-flags'}>
                        <div
                            className={"first-time-user-profile-language-select " + (selectedLanguage === 'lt' ? 'active-flag-first-time' : '')}
                            onClick={() => changeLanguage('lt')}>
                            <img src={FlagLt} alt="Flag of Lithuania"
                                 className={'flag-first-time-setup '}/>
                            <div className={"title no-margin first-time-user-profile-language-text"}>
                                {t("lithuanian")}
                            </div>
                        </div>
                        <div
                            className={"first-time-user-profile-language-select " + (selectedLanguage === 'lv' ? 'active-flag-first-time' : '')}
                            onClick={() => changeLanguage('lv')}>
                            <img src={FlagLv} alt="Flag of Latvia"
                                 className={'flag-first-time-setup '}/>
                            <div className={"title no-margin first-time-user-profile-language-text"}>
                                {t("latvian")}
                            </div>
                        </div>
                        <div
                            className={"first-time-user-profile-language-select " + (selectedLanguage === 'ee' ? 'active-flag-first-time' : '')}
                            onClick={() => changeLanguage('ee')}>
                            <img src={FlagEe} alt="Flag of Estonia"
                                 className={'flag-first-time-setup '}/>
                            <div className={"title no-margin first-time-user-profile-language-text"}>
                                {t("estonian")}
                            </div>
                        </div>
                        <div
                            className={"first-time-user-profile-language-select " + (selectedLanguage === 'en' ? 'active-flag-first-time' : '')}
                            onClick={() => changeLanguage('en')}>
                            <img src={FlagUk} alt="Flag of United Kingdom"
                                 className={'flag-first-time-setup '}/>
                            <div className={"title no-margin first-time-user-profile-language-text"}>
                                {t("english")}
                            </div>
                        </div>
                    </div>
                    <StyledButton1 style={BUTTON_STYLE.STYLE_2} onClick={saveSelectedLanguage}>
                        <div className={"button-content"}>
                            {t('save')}
                            <SaveIcon/>
                        </div>
                    </StyledButton1>
                </div>
            </div>
        </div>
    </div>
}