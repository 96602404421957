import './DoubleCard.css';
import {ReactNode} from "react";

interface Props {
    firstContent: ReactNode;
    secondContent: ReactNode; // Allow JSX.Element or string
    onClick?: () => void;
    className?: string;
}

export const DoubleCard : React.FC<Props>= ({ firstContent, secondContent, onClick,className }) => {

    return  <div className={`double-card ${className ? className : ""}`} onClick={onClick !== undefined ? onClick : () => {}}>
        {firstContent}
        {secondContent}
    </div>
}