import {GlobalBuilder} from "../../../global/GlobalBuilder";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import {useEffect, useState} from "react";
import {PurchasableItemCategory} from "../../../models/data/PurchasableItemCategory";
import {UserInfo} from "../../../models/data/UserInfo";

interface ExportProps{
    shopCategories : PurchasableItemCategory[] | null;
    userInfo: UserInfo | undefined;
}

export const useShop = () : ExportProps => {
    const {userInfo} = useGlobalContext();
    const shopController = GlobalBuilder.getInstance().getShopController();
    const [shopCategories, setShopCategories] = useState<PurchasableItemCategory[] | null>(null);

    useEffect(() => {
        if (userInfo !== undefined){
            getAllShopCategories();
        }
    }, [userInfo])

    const getAllShopCategories = async () => {
        let response = await shopController.getAllShopCategories(userInfo?.token ?? "")
        if (response !== null) {
            setShopCategories(response);
        } else {
            console.error('Failed to retrieve shop categories.');
        }
    }

    return {
        shopCategories,
        userInfo
    }
}