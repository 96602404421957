import './Vote.css';
import {useTranslation} from "react-i18next";
import {BouncingHeartIcon, ScrollBounceIcon} from "../../../global/icons";
import React from "react";
import {BalticFlags} from "../../components/emote/BalticFlags";
import {AnimatedElementsFromBottom} from "../../components/animation/AnimatedElementsFromBottom";
import {AnimatedElementsOpacity} from "../../components/animation/AnimatedElementsOpacity";
import {Warning} from "../../components/content/Warning";

const VoteLinks = ["https://minelist.lt/server-mcbaltics-com.290/vote", "https://m-craft.lt/serveris/mcbaltics-com",
"https://minecraft-server-list.com/server/504156/vote/", "https://minecraftbestservers.com/server-mcbaltics.2772/vote",
"https://minecraft-mp.com/server/331634/vote/","https://minecraftservers.org/vote/661857"];

export const Vote = () => {
    const {t} = useTranslation();

    const EncourageToVote = () => <React.Fragment>
        <AnimatedElementsOpacity>
            <div className={'big-title'}>
                <span className="emoji emoji-vote" role="img" aria-label="monkey"></span>
                {t("vote_for")} {t("mcbaltics")} {t("and_empower_our_community")}!
            </div>
            <div className={'title'}>{t("dear_0_family", {0: t("mcbaltics")})}</div>
            <div className={'text'}>
                {t("vote_text_1", {0: t("mcbaltics")})}
            </div>
        </AnimatedElementsOpacity>

        <AnimatedElementsOpacity>
            <div className={'title margin-top'}>{t("how_can_you_help")}</div>
            <div className={'text'}>
                {t("vote_text_2")}
            </div>
            <div className={'text'}>
                {t("vote_text_3")}
            </div>
        </AnimatedElementsOpacity>

        <AnimatedElementsOpacity>
            <div className={'big-title margin-top'}>
                <span className="emoji emoji-why-vote" role="img" aria-label="monkey"></span>
                {t("why_vote")}?
            </div>
            <div className={'text'}>
                <span className={"rotate-animation"}>🏆</span> {t("why_vote_1")}
            </div>
            <div className={'text'}>
                <span className={"rotate-animation"}>⚔️</span> {t("why_vote_2", {0: t("mcbaltics")})}
            </div>
            <div className={'text'}>
                <span className={"rotate-animation"}>⭐</span> {t("why_vote_3", {0: t("mcbaltics")})}
            </div>
        </AnimatedElementsOpacity>

        <AnimatedElementsOpacity>
            <div className={'big-title margin-top'}>
                <span className="emoji emoji-thanks" role="img" aria-label="monkey"></span>
                {t("thanks")}
            </div>
            <div className={'text'}>
                {t("thanks_1", {0: t("mcbaltics")})}
            </div>
            <div className={'text'}>
                <BouncingHeartIcon style={{color: "red"}}/> {t("thanks_2")} <BouncingHeartIcon
                style={{color: "red"}}/> {t("thanks_3")} {t("mcbaltics")}! <BouncingHeartIcon style={{color: "red"}}/>
            </div>
        </AnimatedElementsOpacity>

        <AnimatedElementsOpacity>
            <div className={'title'}>{t("thanks_4")}<br/>
                {t("thanks_5", {0: t("mcbaltics")})}<BalticFlags/>
            </div>
        </AnimatedElementsOpacity>
    </React.Fragment>

    const RedirectToVote = (link: string) => {
        window.open(link, '_blank');
    }

    const RenderVoteLink = (link: string, index: number) => <AnimatedElementsFromBottom>
        <div className={'vote-link-card'} onClick={() => RedirectToVote(link)}>
            <div className={"vote-link"}>
                Link #{index + 1}
            </div>
            <div className={"vote-description"}>
                <b>{t("how_to_vote")}:</b>
            </div>
            <div className={"vote-description"}>
                <ScrollBounceIcon style={{color: "var(--lime)", marginRight: "10px"}}/>{t("how_to_vote_1")}<br/>
                <ScrollBounceIcon style={{color: "var(--lime)", marginRight: "10px"}}/>{t("how_to_vote_2")}<br/>
                <ScrollBounceIcon style={{color: "var(--lime)", marginRight: "10px"}}/>{t("how_to_vote_3")}
            </div>
            <div className={"vote-description"}>
                <b>{t("rewards")}:</b>
            </div>
            <div>
                800 X BaltiCoins
            </div>
        </div>
    </AnimatedElementsFromBottom>

    return <React.Fragment>
        <AnimatedElementsOpacity>
            <div className={"background-title"}>
                {t("vote")}:
            </div>
        </AnimatedElementsOpacity>
        <div className={'vote-links-container'}>
            {VoteLinks.map((link, index) =>
            <AnimatedElementsFromBottom>
                {RenderVoteLink(link, index)}
                </AnimatedElementsFromBottom>
            )}
        </div>
        <AnimatedElementsOpacity>
            <div className={'text-content'} style={{marginTop: "30px"}}>
                {EncourageToVote()}
            </div>
        </AnimatedElementsOpacity>
    </React.Fragment>
}