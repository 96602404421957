export const PLAY_SERVER_LINK = "mc.mcbaltics.com";
export const PLAY_SERVER_PORT = 25565;
export const API_URL = 'https://webapi.mcbaltics.com';//'http://127.0.0.1:8394';
export const DISCORD_INVITE_LINK = "https://discord.gg/mcbaltics";

export const ROUTER_HOME = 'home'
export const ROUTER_VOTE = 'vote';
export const ROUTER_ABOUT = 'about';
export const ROUTER_LOGIN = 'login';
export const ROUTER_FORUM = 'forum';
export const ROUTER_NEW = 'new';
export const ROUTER_PROFILE = 'profile';
export const ROUTER_WIKI = 'wiki';
export const ROUTER_RULES = 'rules';
export const ROUTER_SHOP = 'store';
export const ROUTER_PASYERA = 'paysera';
export const ROUTER_CANCEL = 'cancel';
export const ROUTER_ACCEPT = 'accept';
export const ROUTER_ERROR = 'error';
export const ROUTER_PARAM_ID = ':id';
export const ROUTER_PARAM_ID_2 = ':id2';
export const ROUTER_BACK = '.';


export enum PageAction {
    NEXT,
    LAST,
    BEFORE,
    FIRST
}

export enum UserTypes {
    Player = 0
}

export enum ThreadReview {
    UNSET = 0,
    LIKE = 1,
    DISLIKE = 2
}
