import {API_URL} from "../global/Constants";
import {AjaxHelper} from "../helpers/AjaxHelper";
import {ResponseLogin} from "../models/response/ResponseLogin";
import {RequestLogin} from "../models/request/RequestLogin";

export class AuthenticationController{
    private readonly ajaxHelper : AjaxHelper;

    constructor(ajaxHelper : AjaxHelper) {
        this.ajaxHelper = ajaxHelper;
    }

    async loginAsync(username: string,password:string): Promise<ResponseLogin | null> {
        try {
            const loginData: RequestLogin = {
                Username: username,
                Password: password,
            };
            const url = `${API_URL}/api/Authentication/login`;
            return await this.ajaxHelper.ajaxPost<ResponseLogin>(url, loginData)
        } catch (error : any) {
            console.error(`Error in getMinecraftPlayerCount: ${error.message}`);
        }
        return null;
    }
}