// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './resources/language/locales/en.json';
import lv from './resources/language/locales/lv.json';
import lt from './resources/language/locales/lt.json';
import ee from './resources/language/locales/ee.json';

const resources = {
    en: { translation: en },
    lt: { translation: lt },
    lv: { translation: lv },
    ee: { translation: ee },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;