import {DiscordIcon, DiscordShakeIcon, GamepadIcon, GamepadShakeIcon} from "../../../global/icons";
import React, {useEffect, useState} from "react";
import './NavigationBarExtras.css';
import {PLAY_SERVER_LINK} from "../../../global/Constants";
import {useTranslation} from "react-i18next";
import {FieldLoader} from "../../../global/display-components/field-loader/FieldLoader";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import i18n from "i18next";

export const NavigationBarExtrasPlay = () => {
    const {playerOnlineCount} = useGlobalContext();
    const {t} = useTranslation();
    const [copyText, setCopyText] = useState<string>(t('click_to_copy'));
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
    const [shakeIcon, setShakeIcon] = useState(false);

    useEffect(() => {
        return () => {
            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    useEffect(() => {
        setCopyText(t('click_to_copy'));
    }, [i18n.language])

    const copyPlayLink = () => {
        navigator.clipboard.writeText(PLAY_SERVER_LINK);
        setCopyText(t('copied'))
        const id = setTimeout(() => {
            setCopyText(t('click_to_copy'));
        }, 1000);

        setTimeoutId(id);
    }

    const makeIconShake = (shake: boolean) => {
        setShakeIcon(shake);
    }

    return <div className={'navigation-bar-extra-content-play-wrapper'}
                onClick={copyPlayLink}
                onMouseEnter={() => makeIconShake(true)}
                onMouseLeave={() => makeIconShake(false)}>
        <div className={'navigation-bar-extra-content navigation-bar-extra-content-play'}>
            <div className={'navigation-bar-extra-play-count'}>
                {playerOnlineCount !== undefined ? playerOnlineCount : <FieldLoader/>}&nbsp;{t('online_players')}
            </div>
            <div className={'navigation-bar-extra-play-icon'}>
                {shakeIcon ?
                    <GamepadShakeIcon style={{fontSize: '30px', color: "var(--lime)"}}></GamepadShakeIcon>
                    :
                    <GamepadIcon style={{fontSize: '30px', color: "var(--lime)"}}></GamepadIcon>}
            </div>
            <div className={'navigation-bar-extra-play-link-content'}>
                <div className={'navigation-bar-extra-play-link'}>
                    {PLAY_SERVER_LINK.toUpperCase()}
                </div>
                <div className={'navigation-bar-play-link-copy'}>
                    {copyText.toLocaleUpperCase()}
                </div>
            </div>
        </div>
    </div>
}