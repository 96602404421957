import {DiscordIcon, DiscordShakeIcon} from "../../../global/icons";
import React, {useEffect, useState} from "react";
import './NavigationBarExtras.css';
import {DISCORD_INVITE_LINK} from "../../../global/Constants";
import {useTranslation} from "react-i18next";
import {FieldLoader} from "../../../global/display-components/field-loader/FieldLoader";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import i18n from "i18next";

export const NavigationBarExtrasDiscord = () => {
    const { playerDiscordCount } = useGlobalContext();
    const { t} = useTranslation();
    const [copyText, setCopyText] = useState<string>(t('click_to_join'));
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
    const [shakeIcon, setShakeIcon] = useState(false);

    useEffect(() => {
        return () => {
            if (timeoutId !== undefined) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    useEffect(() => {
        setCopyText(t('click_to_join'));
    }, [i18n.language])

    const copyPlayLink = () => {
        window.open(DISCORD_INVITE_LINK, '_blank');
        setCopyText(t('opened_in_new_window'))
        const id = setTimeout(() => {
            setCopyText(t('click_to_join'));
        }, 1000);
        setTimeoutId(id);
    }

    const makeIconShake = (shake: boolean) => {
        setShakeIcon(shake);
    }

    return <div className={'navigation-bar-extra-content-discord-wrapper'}
                onClick={copyPlayLink}
                onMouseEnter={() => makeIconShake(true)}
                onMouseLeave={() => makeIconShake(false)}>
        <div className={'navigation-bar-extra-content navigation-bar-extra-content-discord'}>
            <div className={'navigation-bar-extra-discord-count'}>
                {playerDiscordCount ? playerDiscordCount : <FieldLoader/>}&nbsp;{t('online_member')}
            </div>
            <div className={'navigation-bar-extra-discord-link-content'}>
                <div className={'navigation-bar-extra-discord-link'}>
                    {t('discord_server').toUpperCase()}
                </div>
                <div className={'navigation-bar-discord-link-copy'}>
                    {copyText.toLocaleUpperCase()}
                </div>
            </div>
            <div className={'navigation-bar-extra-discord-icon'}>
                {shakeIcon ?
                    <DiscordShakeIcon style={{fontSize: '30px', color: "var(--lime)"}}></DiscordShakeIcon>
                    :
                    <DiscordIcon style={{fontSize: '30px', color: "var(--lime)"}}></DiscordIcon>}
            </div>
        </div>
    </div>
}