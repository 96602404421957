import {useTranslation} from "react-i18next";
import React from "react";
import {Warning} from "../../../components/content/Warning";
import {BackButton} from "../../../components/button/BackButton";

export const ForumRules = () => {
    const {t} = useTranslation();

    return <div className={"text-content"}>
        <div className={"big-title"}>
            <span className="emoji emoji-vote" role="img" aria-label="monkey"></span>
            {t("forum_rules")}
        </div>
        <BackButton/>
        <Warning style={{marginTop: "40px"}}>
            <div className={'title'}>{t("language_policy")}:</div>
            <div className={'text'}>
                {t("language_policy_explanation")}
            </div>
        </Warning>
        <div className={"big-title margin-top"}>{t("forum_rules_1")}:</div>
        <div className={"title"}>{t("forum_rules_1_1")}:</div>
        <div className={'text'}>
            {t("forum_rules_1_1_1")}
        </div>
        <div className={"title"}>{t("forum_rules_1_2")}:</div>
        <div className={'text'}>
            {t("forum_rules_1_2_1")}
        </div>
        <div className={"title"}>{t("forum_rules_1_3")}:</div>
        <div className={'text'}>
            {t("forum_rules_1_3_1")}
        </div>
        <div className={"big-title margin-top"}>{t("forum_rules_2")}:</div>
        <div className={"title"}>{t("forum_rules_2_1")}:</div>
        <div className={'text'}>
            {t("forum_rules_2_1_1")}
        </div>
        <div className={"title"}>{t("forum_rules_2_2")}:</div>
        <div className={'text'}>
            {t("forum_rules_2_2_1")}
        </div>
        <div className={"title"}>{t("forum_rules_2_3")}:</div>
        <div className={'text'}>
            {t("forum_rules_2_3_1")}
        </div>
        <div className={"title"}>{t("forum_rules_2_4")}:</div>
        <div className={'text'}>
            {t("forum_rules_2_4_1")}
        </div>
        <div className={"big-title margin-top"}>{t("forum_rules_3")}:</div>
        <div className={"title"}>{t("forum_rules_3_1")}:</div>
        <div className={'text'}>
            {t("forum_rules_3_1_1")}
        </div>
        <div className={"title"}>{t("forum_rules_3_2")}:</div>
        <div className={'text'}>
            {t("forum_rules_3_2_1")}
        </div>
        <div className={"title"}>{t("forum_rules_3_3")}:</div>
        <div className={'text'}>
            {t("forum_rules_3_3_1")}
        </div>
        <div className={"big-title margin-top"}>{t("forum_rules_4")}:</div>
        <div className={"title"}>{t("forum_rules_4_1")}:</div>
        <div className={'text'}>
            {t("forum_rules_4_1_1")}
        </div>
        <div className={'text'}>
            {t("forum_rules_4_1_2")}
        </div>
        <div className={'text'}>
            {t("forum_rules_4_1_3")}
        </div>
    </div>
}