import {PurchasableItem} from "../../../models/data/PurchasableItem";
import {PurchasableItemDescription} from "../../../models/data/PurchasableItemDescription";
import {useTranslation} from "react-i18next";
import {McBalticsImageWithFallback, ProfileImageWithFallback} from "../../components/utils/ProfileImageWithFallback";
import React, {useState} from "react";
import {getPrice} from "../../../global/Utils";
import "./ShopItemDescription.css";
import {BUTTON_STYLE, StyledButton1} from "../../components/button/Buttons";
import {useShopItemDescription} from "./useShopItemDescription";
import {ResponseGetShopItemWithDescription} from "../../../models/response/ResponseGetShopItemWithDescription";
import {CloseIcon} from "../../../global/icons";

interface Props {
    purchasableItem: PurchasableItem;
    purchasableItemDescription: PurchasableItemDescription;
    setItemModalData: (response: ResponseGetShopItemWithDescription | null) => void;
}

export const ShopItemDescription = (props: Props) => {
    const {purchasableItem, purchasableItemDescription, setItemModalData} = props;
    const {t} = useTranslation();
    const[email, setEmail] = useState<string | undefined>(undefined);
    const {
        createPayseraPaymentRequest,
        error
    } = useShopItemDescription(props.purchasableItem.id,
        props.purchasableItem.priceInCents * props.purchasableItem.priceMultiplier,
        setEmail);

    const replaceTranslations = (html: string): string => {
        // Regular expression to match translation keys, e.g., "{t('key')}"
        const regex = /\{t\("([^']+?)"\)\}/g;
        html = html.replace(regex, (match, key) => t(key)); // Replace translation keys with translated values
        return html;
    };

    const isFilled = (text: string | undefined) => {
        return text === undefined || text;
    }

    return <div className={"shop-item-description-container"}>
        <div className={"shop-item-description-container-close"} onClick={() => setItemModalData(null)}>
            <CloseIcon/>
        </div>
        <div className={"shop-item-description-summary"}>
            <div className={"shop-item-description-summary-content"}>
                <McBalticsImageWithFallback src={purchasableItem.base64Photo}
                                          alt="Profile"
                                          style={{
                                       width: '200px',
                                       height: "200px",
                                       objectFit: "cover",
                                       position: "relative",
                                       borderRadius: "5px"
                                   }}/>
                <div className={"shop-item-description-summary-info-content"}>
                    <div>
                        <div style={{textShadow: "0 0 4px white"}}>
                            {t(purchasableItem.nameKey)}
                        </div>
                        <div>
                            {getPrice(purchasableItem.priceInCents, purchasableItem.priceMultiplier)}
                        </div>
                    </div>
                    <StyledButton1 style={BUTTON_STYLE.STYLE_4}
                                   classes={"shop-item-description-summary-info-content-button"}
                                   onClick={() => createPayseraPaymentRequest(email)}>
                        <strong
                            className={"shop-item-description-summary-info-content-button-content"}>{t("buy")}</strong>
                    </StyledButton1>
                </div>
            </div>
            <div className="no-margin delayed-after-error-warning" style={{textTransform:"none", borderRadius: "0"}}>
                {t('to_receive_purchased_goods_explanation')}
            </div>
            {typeof error === 'string' &&
                <div className="no-margin error" style={{textTransform:"none", borderRadius: "0"}}>
                    {error}
                </div>}
            {typeof error === 'boolean' &&
                <div className="no-margin delayed-after-error-warning email-provide-content" style={{borderRadius: "0", marginTop:"0px"}}>
                    <div style={{fontSize: "25px", marginBottom:"20px"}}>{t("email_required")}</div>
                    <div style={{marginBottom:"20px"}}>{t("type_email_below_and_repeat_the_operation")}</div>
                    <div className={`inputBox ${!isFilled(email) ? "display-input-error" : ""}`}
                         data-content={t("email_required")}>
                        <input
                            type="text"
                            required
                            onChange={(e: any) => setEmail(e.target.value)}
                        />
                        <i className="text no-margin bold">{t('email').toUpperCase()}</i>
                    </div>
                    <div style={{marginTop:"40px", fontSize: "10px"}}>{t("provided_email_will_be_used_for_this_purchase_and_saved_to_your_profile")}</div>
                </div>}
        </div>
        {purchasableItemDescription &&
            <div className={"shop-item-description-content"}>
                <div className={"text no-margin"}>
                    <div dangerouslySetInnerHTML={{__html: replaceTranslations(purchasableItemDescription.html)}}/>
                </div>
            </div>}
    </div>
}