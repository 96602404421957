import {useGlobalContext} from "../../../../global/context/GlobalContext";
import {GlobalBuilder} from "../../../../global/GlobalBuilder";
import {RequestCreateThread} from "../../../../models/request/RequestCreateThread";
import {ResponseCreateThread} from "../../../../models/response/ReponseCreateThread";

interface ExportProps{
    createThreadAsync : (categoryId: number, title: string, description: string) => Promise<ResponseCreateThread | null>;
}

export const useNewThread = ():ExportProps => {
    const {userInfo} = useGlobalContext();
    const forumController = GlobalBuilder.getInstance().getForumController();

    const createThreadAsync = async (categoryId: number, title: string, description: string) => {
        let createThread = new RequestCreateThread(categoryId, title,description);
        return await forumController.createThreadAsync(createThread, userInfo?.token ?? "");
    }

    return{
        createThreadAsync
    }
}