import "./PayseraCancel.css";
import {useTranslation} from "react-i18next";
import {BUTTON_STYLE, StyledButton1} from "../../../components/button/Buttons";
import {Link} from "react-router-dom";
import React from "react";
import {useGlobalContext} from "../../../../global/context/GlobalContext";
import {ROUTER_SHOP} from "../../../../global/Constants";
import { CanceledIcon } from "../../../../global/icons";

export const PayseraCancel = () => {
    const {t} = useTranslation();
    const {handleNavigationChange} = useGlobalContext();

    return <div className={"paysera-cancel-container"}>
        <div className={"big-title no-margin"}>
            {t("payment_canceled")}
        </div>
        <div className={"text"}>
            {t("your_payment_was_canceled")}
        </div>
        <CanceledIcon style={{fontSize:"80px", color: "red", padding: "20px 0 20px 0"}} />
        <div className={"title"}>
            {t("return_to_store")}?
        </div>
        <Link to={`/${ROUTER_SHOP}`}
              onClick={() => handleNavigationChange(ROUTER_SHOP)}
              className={"paysera-cancel-button"}>
            <StyledButton1 children={<strong className={"paysera-cancel-button-content"}>{t("store")}</strong>}
                           style={BUTTON_STYLE.STYLE_4}/>
        </Link>
    </div>
}