export class RequestUpdateUserData {
    public ProfilePictureUrl: string | undefined;
    public LanguagePreference: string | undefined;

    constructor(ProfilePictureUrl?: string | undefined, LanguagePreference?: string | undefined) {
        this.ProfilePictureUrl = ProfilePictureUrl;
        this.LanguagePreference = LanguagePreference;
    }

    public setLanguagePreference(LanguagePreference: string | undefined) {
        this.LanguagePreference = LanguagePreference;
    }

    public setProfilePicuteUrl(ProfilePictureUrl: string | undefined) {
        this.ProfilePictureUrl = ProfilePictureUrl;
    }
}