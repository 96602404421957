import "./Login.css";
import {BUTTON_STYLE, StyledButton1} from "../../components/button/Buttons";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {ROUTER_HOME} from "../../../global/Constants";
import {useNavigate} from "react-router-dom";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import {LoginIcon} from "../../../global/icons";
import {BackButton} from "../../components/button/BackButton";

export const Login = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {loginAsync, setLoading} = useGlobalContext();
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);


    const login = async () => {
        setError("");
        if (fromIsValid()) {
            try {
                setLoading(true);
                if (await loginAsync(username as string, password as string)) {
                    navigate(-1);
                }
            } catch (error) {
                console.error('Error during login:', error);
            }
        }
        setError(t("login_failed_error"));
        setLoading(false);
    };

    const fromIsValid = (): boolean => {
        return !!(username && password);
    }

    const isFilled = (text: string | undefined) => {
        return text === undefined || text;
    }

    return (
        <div className="login-view">
            <div className="login-form">
                <div className="big-title no-margin">{t('sign_in').toUpperCase()}</div>
                {error &&
                    <div className={"inputBox"}>
                        <div className="no-margin error">
                            {t("login_failed_error")}.
                        </div>
                        <div className="no-margin delayed-after-error-warning">
                            <div style={{marginBottom: '5px'}}>{t("login_failed_warning")}</div>
                            <div>{t("login_failed_warning_2")}</div>
                        </div>
                    </div>}
                <div className={`inputBox ${!isFilled(username) ? "display-input-error" : ""}`}
                     data-content={t("username_required")}>
                    <input
                        type="text"
                        required
                        onChange={(e: any) => setUsername(e.target.value)}
                    />
                    <i className="text no-margin bold">{t('username').toUpperCase()}</i>
                </div>
                <div className={`inputBox ${!isFilled(password) ? "display-input-error" : ""}`}
                     data-content={t("password_required")}>
                    <input
                        type="password"
                        required
                        onChange={(e: any) => setPassword(e.target.value)}
                    />
                    <i className="text no-margin bold">{t('password').toUpperCase()}</i>
                </div>
                <div style={{marginTop: "10px"}}>
                    <StyledButton1 style={BUTTON_STYLE.STYLE_2} onClick={login}
                                   classes={!fromIsValid() ? "disabled" : ""}>
                        <div className={"button-content"}>
                            {t('login')}
                            <LoginIcon/>
                        </div>
                    </StyledButton1>
                </div>
                <div className="text no-margin" style={{marginTop: "-20px"}}>
                    <BackButton />
                </div>
            </div>
        </div>
    );
};