import {Success} from "../models/response/Success";
import {useGlobalContext} from "../global/context/GlobalContext";

export class AjaxHelper {
    // Generic AJAX GET method
    async ajaxGet<T>(url: string, headers: Record<string, string> = {}): Promise<T | null> {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            if (response.status === 401){
                localStorage.removeItem('mcBaltics');
            }

            const data: T = await response.json();
            return data;
        } catch (error : any) {
            console.error(`Error in AJAX GET request: ${error.message}`);
            return null;
        }
    }

    // Generic AJAX POST method
    async ajaxPost<T>(url: string, requestData: Record<string, any> = {}, headers: Record<string, string> = {}): Promise<T | null> {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            if (response.status === 202) {
                return new Success() as T; // No content, return null or handle accordingly
            }

            if (response.status === 401){
                localStorage.removeItem('mcBaltics');
            }

            return await response.json();
        } catch (error: any) {
            console.error(`Error in AJAX POST request: ${error.message}`);
            return null;
        }
    }
}