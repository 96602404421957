import './Buttons.css';
import React from "react";

export enum BUTTON_STYLE {
    STYLE_1,
    STYLE_2,
    STYLE_3,
    STYLE_4
}

interface StyledButtonProps {
    onClick?: () => void;
    style: BUTTON_STYLE;
    classes?: string | undefined;
    children: React.ReactNode;
}

export const StyledButton1 = (props: StyledButtonProps) => {
    const {style, onClick, classes, children} = props;

    const performClick = () => {
        if (onClick !== undefined) {
            onClick();
        }
    }

    switch (style) {
        case     BUTTON_STYLE.STYLE_2:
            return <div className={`button-style-2 ${classes ? classes : ""}`} onClick={() => performClick()}>
                <span className={'title-uncolored'}>{children}</span>
            </div>
        case     BUTTON_STYLE.STYLE_3:
            return <div className={`button-style-3 ${classes ? classes : ""}`} onClick={() => performClick()}>
                {children}
            </div>
        case     BUTTON_STYLE.STYLE_4:
            return <div className={`button-style-4 ${classes ? classes : ""}`} onClick={() => performClick()}>
                {children}
                <div id="container-stars">
                    <div id="stars"></div>
                </div>

                <div id="glow">
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
            </div>
        default:
            return <div></div>
    }
}