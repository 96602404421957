import "./Thread.css";
import {useThread} from "./useThread";
import {useNavigate, useParams} from "react-router-dom";
import {BUTTON_STYLE, StyledButton1} from "../../../components/button/Buttons";
import {
    CommentsIcon,
    EyeIcon,
    LeftIcon,
    LeftLeftIcon,
    RightIcon,
    RightRightIcon,
    SendIcon,
    ThumbsDownIcon,
    ThumbsDownUnactiveIcon,
    ThumbsUpIcon,
    ThumbsUpUnactiveIcon
} from "../../../../global/icons";
import React, {useRef} from "react";
import {AnimatedElementsOpacity} from "../../../components/animation/AnimatedElementsOpacity";
import {ContentLoader} from "../../../../global/display-components/content-loader/ContentLoader";
import {ThreadPost} from "../../../../models/data/ThreadPost";
import {PageAction, ROUTER_PROFILE, ThreadReview} from "../../../../global/Constants";
import {FormatDateFromISOString, IsFilled} from "../../../../global/Utils";
import {ProfileImageWithFallback} from "../../../components/utils/ProfileImageWithFallback";
import {UserTags} from "../../../components/utils/UserTags";
import {useTranslation} from "react-i18next";
import {Warning} from "../../../components/content/Warning";
import {BackButton} from "../../../components/button/BackButton";
import {UserStatistics} from "../../../components/utils/UserStatistics";
import {Tooltip} from "../../../components/utils/Tooltip";

export const Thread = () => {
    const {id, id2} = useParams();
    const parsedId = id2 ? parseInt(id2) : NaN;
    const navigate = useNavigate();
    const {
        responseGetThreadPage,
        setCurrentPage,
        currentPage,
        setCurrentPageWithLogic,
        userInfo,
        commentThreadAsync,
        error,
        success,
        comment,
        setComment,
        reviewPost,
        acceptForumRulesRef,
        acknowledgeConsequencesRef
    } = useThread(parsedId);
    const {t} = useTranslation();

    if (isNaN(parsedId)) {
        navigate("/error");
        return null;
    }

    if (responseGetThreadPage === undefined) {
        return <div className={"text-content"}>
            <ContentLoader style={{height: "50vh", marginTop: "50px", minHeight: "700px"}}/>
        </div>
    }

    const displayThreadTitle = () =>
        <StyledButton1
            classes={"thread-title-container"}
            children={<strong className={"thread-title-container-strong"}>
                <div className={"no-margin"}>
                    {FormatDateFromISOString(responseGetThreadPage.forumThread.dateCreated.toString())}
                </div>
                <div className={"big-title no-margin"}>
                    {responseGetThreadPage.forumThread.title}
                </div>
                <div className={"no-margin thread-title-parameters"}>
                    <Tooltip text={t("replies")}>
                        <div className={"thread-title-parameter"}>
                            {responseGetThreadPage.forumThread.replyCount}<CommentsIcon/>
                        </div>
                    </Tooltip>
                    <Tooltip text={t("views")}>
                        <div className={"thread-title-parameter"}>
                            {responseGetThreadPage.forumThread.viewCount}<EyeIcon/>
                        </div>
                    </Tooltip>
                </div>
            </strong>}
            style={BUTTON_STYLE.STYLE_4} />

    const pagesArray = () => {
        let lowerLimit = currentPage - 2;
        let higherLimit = currentPage + 2;
        let pages: number[] = [];
        let lowerLimitReached = false;
        let higherLimitReached = false;

        for (let i = 1; i <= (responseGetThreadPage?.totalPageCount ?? 1); i++) {
            if (i < lowerLimit) {
                lowerLimitReached = true;
                continue;
            }
            if (i > higherLimit) {
                higherLimitReached = true;
                continue;
            }
            pages.push(i);
        }

        if (lowerLimitReached) {
            pages.unshift(-1);
        }
        if (higherLimitReached) {
            pages.push(-1);
        }

        return pages;
    }

    const renderThreadPages = () => <div className={"forum-threads-pages"}>
        <div className={"forum-thread-page"} onClick={() => setCurrentPageWithLogic(PageAction.FIRST)}><LeftLeftIcon/>
        </div>
        <div className={"forum-thread-page"} onClick={() => setCurrentPageWithLogic(PageAction.BEFORE)}><LeftIcon/>
        </div>
        {pagesArray().map((pageNumber, i) => {
            if (pageNumber === -1) {
                return <div key={i}>...</div>;
            }
            if (pageNumber === currentPage) {
                return <div className={"forum-thread-page forum-thread-active-page"} key={i}>{pageNumber}</div>;
            }
            return <div className={"forum-thread-page"} key={i} onClick={() => {
                setCurrentPage(pageNumber)
            }}>{pageNumber}</div>;
        })}
        <div className={"forum-thread-page"} onClick={() => setCurrentPageWithLogic(PageAction.NEXT)}><RightIcon/></div>
        <div className={"forum-thread-page"} onClick={() => setCurrentPageWithLogic(PageAction.LAST)}><RightRightIcon/>
        </div>
    </div>

    const checkIfThisCommentStatusActive = (postId: number, status: number) => {
        var existingReview = responseGetThreadPage.historyThreadReviews.find(x => x.postId === postId);
        return existingReview && existingReview.status === status;
    }

    const displayForumPost = (threadPost: ThreadPost, key: number) => <div key={key} className={"forum-post-container"}>
        <div className={"forum-post-user"}>
            <ProfileImageWithFallback
                src={threadPost.userData.profilePictureUrl}
                alt="Profile"
                style={{
                    minWidth: '100px',
                    minHeight: "100px",
                    maxWidth: '100px',
                    maxHeight: "100px",
                    objectFit: "cover",
                    border: "2px solid var(--gray)",
                    borderRadius: "5px"
                }}/>
            <div className={"forum-post-user-info"}>
                <Tooltip text={t("click_to_view_profile")}>
                    <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button-thread"}>
                            <span className={"username-span-thread"}
                                  onClick={() => navigate(`../../${ROUTER_PROFILE}/${threadPost.userData.username}`)}>
                                {threadPost.userData.username}
                            </span>
                    </StyledButton1>
                </Tooltip>
                <UserStatistics userData={threadPost.userData} className={"forum-profile-text"}/>
                <UserTags userData={threadPost.userData}/>
                <div className={"forum-profile-text"} style={{marginTop: "20px"}}>
                    {t("joined")} {FormatDateFromISOString(threadPost.userData.dateTimeCreated.toString())}
                </div>
            </div>
        </div>
        <div className={"forum-post-content"}>
            <div className={"forum-post-date"}>
                {FormatDateFromISOString(threadPost.comment.datePosted.toString())}
            </div>
            <pre className={"forum-post-comment"}>
                {threadPost.comment.content}
            </pre>
            <div style={{flex: "1"}}></div>
            <div className={`forum-post-statistics ${userInfo ? "" : "forum-post-statistics-blocked"}`}>
                {!userInfo && <div className={"forum-post-statistics-block"}>{t("login_to_use_this_feature")}</div>}
                <div>
                    {threadPost.comment.upvoteCount}
                    {checkIfThisCommentStatusActive(threadPost.comment.id, ThreadReview.LIKE) ?
                        <Tooltip text={t("unvote")}>
                            <div className={"vote-button"}
                                 onClick={() => reviewPost(threadPost.comment.id, ThreadReview.UNSET)}>
                                <ThumbsUpIcon style={{color: "#989898"}}/>
                            </div>
                        </Tooltip>
                        :
                        <Tooltip text={t("upvote")}>
                            <div className={"vote-button"}
                                 onClick={() => reviewPost(threadPost.comment.id, ThreadReview.LIKE)}>
                                <ThumbsUpUnactiveIcon style={{color: "#989898"}}/>
                            </div>
                        </Tooltip>}
                </div>
                <div>
                    {threadPost.comment.downvoteCount}
                    {checkIfThisCommentStatusActive(threadPost.comment.id, ThreadReview.DISLIKE) ?
                        <Tooltip text={t("unvote")}>
                            <div className={"vote-button"}
                                 onClick={() => reviewPost(threadPost.comment.id, ThreadReview.UNSET)}>
                                <ThumbsDownIcon style={{color: "#989898"}}/>
                            </div>
                        </Tooltip>
                        :
                        <Tooltip text={t("downvote")}>
                            <div className={"vote-button"}
                                 onClick={() => reviewPost(threadPost.comment.id, ThreadReview.DISLIKE)}>
                                <ThumbsDownUnactiveIcon style={{color: "#989898"}}/>
                            </div>
                        </Tooltip>}
                </div>
            </div>
        </div>
    </div>

    const displayThreadReply = () => {
        if (!userInfo) {
            return <Warning>
                <div className={'title'}>{t("login_required")}:</div>
                <div className={'text'}>{t("you_must_login_to_use_this")}!</div>
            </Warning>
        }
        return <React.Fragment>
            <div className={`inputBox ${!IsFilled(comment) ? "display-input-error" : ""}`}
                 data-content={t('comment_required')}
                 data-length={`${comment.length} / ${500}`}>
            <textarea
                required
                onChange={(e: any) => setComment(e.target.value)}
                value={comment}
            />
                <i className="text no-margin bold">{t("comment").toUpperCase()}</i>
            </div>
            <div style={{marginTop: "10px"}}>
            <div className="checkbox-wrapper-4">
                <input className="inp-cbx" id="accept-1" type="checkbox" ref={acceptForumRulesRef}/>
                <label className="cbx" htmlFor="accept-1"><span>
  <svg width="12px" height="10px">
  </svg></span><span>{t("i_have_read_and_agree_to_forum_rules")}</span></label>
                <svg className="inline-svg">
                    <symbol id="check-4" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </symbol>
                </svg>
            </div>
            <div className="checkbox-wrapper-4">
                <input className="inp-cbx" id="accept-2" type="checkbox" ref={acknowledgeConsequencesRef}/>
                <label className="cbx" htmlFor="accept-2"><span>
  <svg width="12px" height="10px">
  </svg></span><span>{t("i_agree_to_consequences_breaking_forum_rules")}</span></label>
                <svg className="inline-svg">
                    <symbol id="check-4" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                    </symbol>
                </svg>
            </div>
        </div>
            <div style={{marginLeft: "auto"}}>
                <StyledButton1 style={BUTTON_STYLE.STYLE_2} classes={"button-style-2"}
                               onClick={() => commentThreadAsync(comment)}>
                    <div className={"button-content"}>
                        {t("reply")}
                        <SendIcon/>
                    </div>
                </StyledButton1>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>
        <div className={"text-content"}>
            <AnimatedElementsOpacity>
                <BackButton/>
            </AnimatedElementsOpacity>
            <AnimatedElementsOpacity>
                {displayThreadTitle()}
                {responseGetThreadPage.threadPosts.length > 0 && renderThreadPages()}
                {responseGetThreadPage.threadPosts.map((x, key) => displayForumPost(x, key))}
                {responseGetThreadPage.threadPosts.length > 0 && renderThreadPages()}
            </AnimatedElementsOpacity>
        </div>
        <AnimatedElementsOpacity>
            <div className={"background-title"}>
                {t("reply")}:
            </div>
            <div className={"text-content"}>
                <div className={"forum-thread-comment-form"}>
                    <div className={"big-title no-margin"}>
                        {t("reply_to_this_thread")}
                    </div>
                    {error &&
                        <div className={"inputBox"}>
                            <div className="no-margin error">
                                {error}
                            </div>
                        </div>}
                    {success &&
                        <div className={"inputBox"}>
                            <div className="no-margin success">
                                {success}
                            </div>
                        </div>}
                    {displayThreadReply()}
                </div>
            </div>
        </AnimatedElementsOpacity>
    </React.Fragment>
}