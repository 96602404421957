import React from "react";
import "./NotFound.css";
import {useTranslation} from "react-i18next";

export const NotFound = () => {
    const {t} = useTranslation();

    return <div className={'text-content'}>
        <div className={'big-title'}><span className="emoji emoji-not-found" role="img" aria-label="monkey"></span>
            {t("sorry_page_not_found")}</div>
        <div className={'text'}>
            {t("sorry_page_not_found_1")}
        </div>
    </div>
}