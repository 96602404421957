import {AjaxHelper} from "../helpers/AjaxHelper";
import {API_URL} from "../global/Constants";
import {RequestUpdateUserData} from "../models/request/RequestUpdateUserData";
import {Success} from "../models/response/Success";
import {ResponseGetUserProfileInfo} from "../models/response/ResponseGetUserProfileInfo";

export class UserController {
    private readonly ajaxHelper: AjaxHelper;

    constructor(ajaxHelper: AjaxHelper) {
        this.ajaxHelper = ajaxHelper;
    }

    async updateUserData(requestUpdateUserData: RequestUpdateUserData, token: string): Promise<boolean> {
        try {
            const url = `${API_URL}/api/User/update_user_data`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };

            let result = await this.ajaxHelper.ajaxPost<Success>(url, requestUpdateUserData, headers);

            return !!result;
        } catch (error: any) {
            console.error(`Error in getMinecraftPlayerCount: ${error.message}`);
        }
        return false;
    }

    async getUserProfileInfo(username: string, token: string): Promise<ResponseGetUserProfileInfo | null> {
        try {
            const url = `${API_URL}/api/User/get_user_profile_info?username=${username}`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };

            return await this.ajaxHelper.ajaxGet<ResponseGetUserProfileInfo>(url, headers);
        } catch (error: any) {
            console.error(`Error in getUserProfileInfo: ${error.message}`);
        }
        return null;
    }
}