import {useTranslation} from "react-i18next";
import "./BottomBar.css"
import {AnimatedElementsOpacity} from "../animation/AnimatedElementsOpacity";

export const BottomBar = () => {
    const {t} = useTranslation();

    return <div className={"bottom-bar-content"}>
        <AnimatedElementsOpacity>
            <div
                className={"bottom-bar-copyright bold"}>&copy; {new Date().getFullYear()} {t("mcbaltics")}. {t("copyright")}.
            </div>
            <div className={"bottom-bar-copyright"}>{t("copyright_not_with_mojang")}.</div>
        </AnimatedElementsOpacity>
    </div>
}