import React, { useState, useEffect, CSSProperties } from "react";
import profileDefaultImage from "../../../resources/images/default-player-photo.png";
import mcBalticsDefaultImage from "../../../resources/images/home-icon.png";

interface ImageWithFallbackProps {
    src: string | undefined | null;
    alt: string;
    style?: CSSProperties;
    classes?: string;
}

export const ProfileImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt, style,classes }) => {
    const [imageSrc, setImageSrc] = useState<string | null | undefined>(src);

    useEffect(() => {
        if (src) {
            setImageSrc(src);
        } else {
            setImageSrc(profileDefaultImage);
        }
    }, [src]);

    const onError = () => {
        if (imageSrc !== profileDefaultImage) {
            setImageSrc(profileDefaultImage);
        }
    };

    return <img src={imageSrc as string} onError={onError} alt={alt} style={style} className={classes ?? ""} />;
};

export const McBalticsImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, alt, style,classes }) => {
    const [imageSrc, setImageSrc] = useState<string | null | undefined>(src);

    useEffect(() => {
        if (src) {
            setImageSrc(src);
        } else {
            setImageSrc(mcBalticsDefaultImage);
        }
    }, [src]);

    const onError = () => {
        if (imageSrc !== mcBalticsDefaultImage) {
            setImageSrc(mcBalticsDefaultImage);
        }
    };

    return <img src={imageSrc as string} onError={onError} alt={alt} style={style} className={classes ?? ""} />;
};
