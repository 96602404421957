import {PostsIcon, ThumbsDownIcon, ThumbsUpIcon} from "../../../global/icons";
import React from "react";
import {UserData} from "../../../models/data/UserData";
import "./UserStatistics.css";
import {Tooltip} from "./Tooltip";
import {useTranslation} from "react-i18next";

interface Props{
    userData: UserData;
    className: string;
}

export const UserStatistics = (props: Props) => {
    const {userData, className} = props;
    const {t} = useTranslation();

    return (
        <div className={`profile-parameters ${className}`}>
            <Tooltip text={t("upvotes_count")} >
                {userData.upvotesCount} <ThumbsUpIcon/>
            </Tooltip>
            <Tooltip text={t("downvotes_count")} >
                {userData.downvotesCount} <ThumbsDownIcon/>
            </Tooltip>
            <Tooltip text={t("posts_count")} >
                {userData.postCount} <PostsIcon/>
            </Tooltip>
        </div>
    );
}