import {GlobalBuilder} from "../../../../global/GlobalBuilder";
import {UserInfo} from "../../../../models/data/UserInfo";
import {useEffect, useState} from "react";
import {ResponseGroupedThreadCategory} from "../../../../models/response/ResponseGroupedThreadCategory";
import {useGlobalContext} from "../../../../global/context/GlobalContext";

interface ExportProps{
    forumCategories: ResponseGroupedThreadCategory[] | null;
}

export const useForumCategories = () => {
    const forumController = GlobalBuilder.getInstance().getForumController();
    const {userInfo} = useGlobalContext();
    const [forumCategories, setForumCategories] = useState<ResponseGroupedThreadCategory[] | null>(null);

    useEffect(() => {
        getForumCategories();
    }, [])

    const getForumCategories = async () => {
        let response = await forumController.getForumCategories(userInfo?.token ?? "")
        if (response !== null) {
            setForumCategories(response);
        } else {
            console.error('Failed to retrieve thread categories.');
        }
    }

    return {
        forumCategories
    }
}