import "./ContentLoader.css";

interface Props{
    style?: React.CSSProperties;
}

export const ContentLoader = (props: Props) =>{
    const {style} = props;

    return <div className={"content-loader-content"} style={style}>
        <div className="content-loader">
            <div className={"content-loader-1"}></div>
            <div className={"content-loader-2"}></div>
        </div>
    </div>
}