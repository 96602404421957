import {useNavigate} from "react-router-dom";
import {GlobalBuilder} from "../../../../global/GlobalBuilder";
import {useEffect, useState} from "react";
import {useGlobalContext} from "../../../../global/context/GlobalContext";
import {ROUTER_HOME} from "../../../../global/Constants";
import {PreparePaymentResponse} from "../../../../models/response/PreparePaymentResponse";

interface ExportProps {
    acceptResponse: PreparePaymentResponse | null;
    success: boolean | undefined;
    redirectTimer: number;
}

export const usePayseraAccept = (data: string, ss1: string, ss2: string): ExportProps => {
    const {userInfo} = useGlobalContext();
    const navigate = useNavigate();
    const shopController = GlobalBuilder.getInstance().getShopController();
    const [acceptResponse, setAcceptResponse] = useState<PreparePaymentResponse | null>(null)
    const [success, setSuccess] = useState<boolean | undefined>(undefined)
    const [redirectTimer, setRedirectTimer] = useState<number>(5);

    useEffect(() => {
        if (userInfo) {
            if (!data || !ss1 || !ss2) {
                navigate("/error");
            } else {
                getAccept();
            }
        }
    }, [userInfo])

    useEffect(() => {
        if (redirectTimer === 0) {
            navigate(`/${ROUTER_HOME}`);
        }

        const interval = setInterval(() => {
            if (success !== undefined && redirectTimer > 0) {
                setRedirectTimer(prevTimer => prevTimer - 1);
            }
        }, 1000);

        // Cleanup function to clear the interval when the component unmounts or when redirectTimer reaches 0
        return () => clearInterval(interval);
    }, [success, redirectTimer])

    useEffect(() => {
        console.log("started")
        const interval = setInterval(() => {
            if (acceptResponse !== null && success === undefined) {
                getOrderCompletion(acceptResponse.orderId);
            }
            console.log("lol")
        }, 1000);

        // Cleanup function to clear the interval when the component unmounts or when redirectTimer reaches 0
        return () => clearInterval(interval);
    }, [acceptResponse])

    const getAccept = async (): Promise<void> => {
        let response = await shopController.getAccept(data, ss1, ss2, userInfo?.token ?? "")
        if (response !== null) {
            setAcceptResponse(response);
            startTimer(60);
        } else {
            console.error('Failed to create a get accept request.');
        }
    }

    const startTimer = (duration: number) => {
        let timeout = setTimeout(() => {
            let time = duration;
            let i = 1;
            let k = ((i / duration) * 100);
            let l = 100 - k;
            i++;
            const c1 = document.getElementById("c1");
            const c2 = document.getElementById("c2");
            const counterText = document.getElementById("counterText");
            if (c1 && c2 && counterText) {
                (c1 as HTMLElement).style.strokeDasharray = `${l} ${k}`;
                (c2 as HTMLElement).style.strokeDasharray = `${k} ${l}`;
                (c1 as HTMLElement).style.strokeDashoffset = `${l}`;
                (counterText as HTMLElement).innerHTML = `${duration}`;
            }
            const interval = setInterval(() => {
                if (i > time) {
                    clearInterval(interval);
                    clearTimeout(timeout);
                    setRedirectTimer(60);
                    setSuccess(false);
                    return;
                }
                k = ((i / duration) * 100);
                l = 100 - k;
                if (c1 && c2) {
                    (c1 as HTMLElement).style.strokeDasharray = `${l} ${k}`;
                    (c2 as HTMLElement).style.strokeDasharray = `${k} ${l}`;
                    (c1 as HTMLElement).style.strokeDashoffset = `${l}`;
                }
                if (counterText) {
                    (counterText as HTMLElement).innerHTML = `${duration + 1 - i}`;
                }
                i++;
            }, 1000);
        }, 0);
    };

    const getOrderCompletion = async (orderId: number): Promise<void> => {
        let response = await shopController.getOrderCompletion(orderId, userInfo?.token ?? "")
        if (response !== null && response === true) {
            setSuccess(true);
        } else {
            console.error('Failed to get order completion request.');
        }
    }

    return {
        acceptResponse,
        success,
        redirectTimer
    }
}