import './About.css';
import {useTranslation} from "react-i18next";
import {BalticFlags} from "../../components/emote/BalticFlags";
import {ScrollBounceIcon} from "../../../global/icons";
import {DISCORD_INVITE_LINK, PLAY_SERVER_LINK} from "../../../global/Constants";
import React from 'react';
import {AnimatedElementsOpacity} from "../../components/animation/AnimatedElementsOpacity";

export const About = () => {
    const {t} = useTranslation();

    const aboutContent = () => <React.Fragment>
        <AnimatedElementsOpacity>
            <div className={'text-content'}>
                <div className={'big-title'} style={{fontSize: "40px", lineHeight:"unset"}}>{t("mcbaltics")}</div>
                <div className={'title'}>{t("about_1")}<BalticFlags/></div>
                <div className={'text'}>{t("about_2")}
                </div>

                <div className={'big-title margin-top'}>
                    <span className="emoji emoji-what-awaits" role="img" aria-label="monkey"></span>
                    {t("about_3")}:
                </div>
                <div className={'text'}>
                    <ul>
                        <li>
                            <ScrollBounceIcon style={{color: "var(--lime)", marginRight: "10px"}}/>{t("about_4")}
                        </li>
                        <li>
                            <ScrollBounceIcon style={{color: "var(--lime)", marginRight: "10px"}}/>{t("about_5")}
                        </li>
                        <li>
                            <ScrollBounceIcon style={{color: "var(--lime)", marginRight: "10px"}}/>{t("about_6")}
                        </li>
                        <li>
                            <ScrollBounceIcon style={{color: "var(--lime)", marginRight: "10px"}}/>{t("about_7")}
                        </li>
                        <li>
                            <ScrollBounceIcon style={{color: "var(--lime)", marginRight: "10px"}}/>{t("about_8")}
                        </li>
                    </ul>
                </div>
            </div>
        </AnimatedElementsOpacity>
        <AnimatedElementsOpacity>
            <div className={"background-title"}>
                {t("join")}:
            </div>
            <div className={"text-content"}>
                <div className={'big-title'}>
                    <span className="emoji emoji-join-mc" role="img" aria-label="monkey"></span>
                    {t("about_9")}:
                </div>
                <div className={'title'}>{t("about_10")}: {PLAY_SERVER_LINK}</div>

                <div className={'big-title margin-top'}>
                    <span className="emoji emoji-join-dc" role="img" aria-label="monkey"></span>
                    {t("about_11")}:
                </div>
                <div className={'title'}>{t("about_12")}: <a href={DISCORD_INVITE_LINK}>{DISCORD_INVITE_LINK}</a></div>
            </div>
        </AnimatedElementsOpacity>
    </React.Fragment>

    return <React.Fragment>
        {aboutContent()}
    </React.Fragment>
}