import React, {ReactNode} from "react";
import "./Tooltip.css";

interface Props{
    children: ReactNode;
    text: string;
}

export const Tooltip = (props: Props) => {
    return <div className="tooltip">
        <span className="tooltiptext">{props.text}</span>
        {props.children}
    </div>
}