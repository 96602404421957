import {useEffect, useState} from "react";
import {GlobalBuilder} from "../../../../global/GlobalBuilder";
import {useGlobalContext} from "../../../../global/context/GlobalContext";
import {ResponseForumThreadPage} from "../../../../models/response/ResponseForumThreadPage";
import {PageAction} from "../../../../global/Constants";
import {UserInfo} from "../../../../models/data/UserInfo";

interface ExportProps{
    currentPage : number;
    setCurrentPageWithLogic: (currentPage: PageAction) => void;
    setCurrentPage: (currentPage: number) => void;
    responseForumThreadsPage : ResponseForumThreadPage | undefined;
    userInfo: UserInfo | undefined;
}

export const useForumThreads = (categoryId: number) : ExportProps => {
    const forumController = GlobalBuilder.getInstance().getForumController();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const {userInfo } = useGlobalContext();
    const [responseForumThreadsPage, setResponseForumThreadsPage] = useState<ResponseForumThreadPage | undefined>()

    useEffect(() => {
        getForumThreads();
    }, [currentPage])

    const getForumThreads = async () => {
        let response = await forumController.getForumThreadPage(categoryId, currentPage, userInfo?.token ?? "")
        if (response !== null) {
            setResponseForumThreadsPage(response);
        } else {
            console.error('Failed to retrieve thread categories.');
        }
    }

    const setCurrentPageWithLogic = (pageAction : PageAction) => {
        switch (pageAction) {
            case PageAction.FIRST:
                if (currentPage !== 1){
                    setCurrentPage(1);
                }
                break;
            case PageAction.BEFORE:
                if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                }
                break;
            case PageAction.NEXT:
                if (currentPage < (responseForumThreadsPage?.totalPageCount ?? 1)){
                    setCurrentPage(currentPage + 1);
                }
                break;
            case PageAction.LAST:
                if (currentPage !== (responseForumThreadsPage?.totalPageCount ?? 1)){
                    setCurrentPage(responseForumThreadsPage?.totalPageCount ?? 1)
                }
                break;
            default:
                break;
        }
    }

    return {
        currentPage,
        setCurrentPageWithLogic,
        setCurrentPage,
        responseForumThreadsPage,
        userInfo
    }
}