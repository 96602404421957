import {GlobalBuilder} from "../../../../global/GlobalBuilder";
import {RefObject, useEffect, useRef, useState} from "react";
import {useGlobalContext} from "../../../../global/context/GlobalContext";
import {ResponseGetThreadPage} from "../../../../models/response/ResponseGetThreadPage";
import {PageAction} from "../../../../global/Constants";
import {UserInfo} from "../../../../models/data/UserInfo";
import {useTranslation} from "react-i18next";
import {RequestCreatePost} from "../../../../models/request/RequestCreatePost";
import {RequestReviewPost} from "../../../../models/request/RequestReviewPost";
import {HistoryThreadReview} from "../../../../models/data/HistoryThreadReview";

interface ExportProps {
    responseGetThreadPage : ResponseGetThreadPage | undefined;
    setCurrentPage : (currentPage : number) => void;
    currentPage : number;
    setCurrentPageWithLogic: (currentPage: PageAction) => void;
    userInfo: UserInfo | undefined;
    commentThreadAsync: (comment: string) => Promise<void>;
    error: string;
    success: string;
    comment: string;
    setComment: (comment :string) => void;
    reviewPost: (postId : number, status: number) => Promise<boolean>;
    acceptForumRulesRef : RefObject<HTMLInputElement>;
    acknowledgeConsequencesRef: RefObject<HTMLInputElement>;
}

export const useThread = (threadId : number) : ExportProps => {
    const forumController = GlobalBuilder.getInstance().getForumController();
    const {userInfo } = useGlobalContext();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [responseGetThreadPage, setResponseGetThreadPage] = useState<ResponseGetThreadPage | undefined>()
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const {setLoading} = useGlobalContext();
    const {t} = useTranslation();
    const [comment, setComment] = useState<string>("");
    const acceptForumRulesRef = useRef<HTMLInputElement>(null);
    const acknowledgeConsequencesRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getThreadPage();
    }, [currentPage, userInfo])

    const getThreadPage = async () => {
        let response = await forumController.getThreadPage(threadId, currentPage, userInfo?.token ?? "")
        if (response !== null) {
            setResponseGetThreadPage(response);
        } else {
            console.error('Failed to retrieve thread categories.');
        }
    }

    const setCurrentPageWithLogic = (pageAction : PageAction) => {
        switch (pageAction) {
            case PageAction.FIRST:
                if (currentPage !== 1){
                    setCurrentPage(1);
                }
                break;
            case PageAction.BEFORE:
                if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                }
                break;
            case PageAction.NEXT:
                if (currentPage < (responseGetThreadPage?.totalPageCount ?? 1)){
                    setCurrentPage(currentPage + 1);
                }
                break;
            case PageAction.LAST:
                if (currentPage !== (responseGetThreadPage?.totalPageCount ?? 1)){
                    setCurrentPage(responseGetThreadPage?.totalPageCount ?? 1)
                }
                break;
            default:
                break;
        }
    }

    const commentThreadAsync = async (comment :string) => {
        setSuccess("");
        setError("");
        setLoading(true);
        if (!!(comment) && comment.length <= 500) {
            const acceptForumRules = acceptForumRulesRef.current?.checked;
            const acknowledgeConsequences = acknowledgeConsequencesRef.current?.checked;

            if (acceptForumRules && acknowledgeConsequences){
                try {
                    let model = new RequestCreatePost(threadId, comment);
                    let response =await forumController.createPostAsync(model, userInfo?.token ?? "");
                    if ((response?.cooldownInSeconds ?? 0) > 0){
                        setError(t("please_wait_0_seconds", {0: response?.cooldownInSeconds ?? 0}));
                    }
                    else if (response?.success === true) {
                        setComment("");
                        setSuccess(t("comment_created"));
                        getThreadPage();
                    }
                    else{
                        setError(t("error"));
                    }
                } catch (error) {
                    setError(t("error"));
                }
            }
            else {
                // At least one checkbox is not checked, show an error message or handle it accordingly
                setError(t("accept_terms_and_conditions"));
                // You can display an error message to the user or handle the situation as needed
            }
        } else {
            if (comment?.length > 500){
                setError(t("comment_too_long"));
            }
            else{
                setError(t("comment_required"));
            }
        }
        setLoading(false);
    };

    const reviewPost = async (postId : number, status: number) => {
        try {
            setLoading(true);
            let model = new RequestReviewPost(postId,status);
            let response = await forumController.reviewPostAsync(model, userInfo?.token ?? "");

            if (response) {
                setResponseGetThreadPage(prevResponse => {
                    if (!prevResponse) return prevResponse;

                    let updatedHistoryThreadReviews : HistoryThreadReview[] = [];
                    if (prevResponse.historyThreadReviews.find(x => x.postId === postId)){
                        updatedHistoryThreadReviews = prevResponse.historyThreadReviews.map(history => {
                            if (history.postId === postId) {
                                // Replace the entire ThreadPost object if the ID matches
                                return {
                                    ...history,
                                    status: status
                                };
                            }
                            return history;
                        });
                    }
                    else{
                        updatedHistoryThreadReviews = prevResponse.historyThreadReviews;
                        updatedHistoryThreadReviews.push(new HistoryThreadReview(
                            0,
                            0,
                            postId,
                            new Date(),
                            status))
                    }

                    let updatedThreadPosts = prevResponse.threadPosts.map(post => {
                        if (post.comment.id === postId) {
                            // Replace the entire ThreadPost object if the ID matches
                            return {
                                ...post,
                                userData: response?.threadPost.userData!,
                                comment: response?.threadPost.comment!
                            };
                        }
                        return post;
                    });

                    return {
                        ...prevResponse,
                        threadPosts: updatedThreadPosts,
                        firstTime: true,
                        historyThreadReviews: updatedHistoryThreadReviews
                    };
                });

                setLoading(false);
                return true;
            }
        } catch (error) {
            console.error('Error Review post:', error);
        }

        setLoading(false);
        return false;
    }

    return {
        responseGetThreadPage,
        setCurrentPage,
        currentPage,
        setCurrentPageWithLogic,
        userInfo,
        commentThreadAsync,
        error,
        success,
        comment,
        setComment,
        reviewPost,
        acceptForumRulesRef,
        acknowledgeConsequencesRef
    }
}