import {useGlobalContext} from "../../../global/context/GlobalContext";
import {GlobalBuilder} from "../../../global/GlobalBuilder";
import {CreateMacroPaymentRequest} from "../../../models/request/CreateMacroPaymentRequest";
import {MacroPaymentCreationStatus} from "../../../global/Enums";
import {useState} from "react";
import {useTranslation} from "react-i18next";

interface ExportProps{
    createPayseraPaymentRequest:  (email: string | undefined) => Promise<void>;
    error : string | boolean |null;
}

export const useShopItemDescription = (itemId: number, amountInCents: number, setEmail: (email: string |undefined) => void) :ExportProps => {
    const {userInfo, setLoading, saveEmailToLocalStorage} = useGlobalContext();
    const shopController = GlobalBuilder.getInstance().getShopController();
    const [error, setError] = useState<string | boolean | null>(null);
    const {t} = useTranslation();

    const createPayseraPaymentRequest = async (email: string | undefined = undefined): Promise<void> => {
        if (!email){
            if (!userInfo?.email){
                setEmail(undefined);
                setError(true);
                return;
            }
            email = userInfo?.email;
        }

        if(!email){
            setEmail(undefined);
            setError(true);
            return;
        }

        setLoading(true);
        let model = new CreateMacroPaymentRequest(itemId, amountInCents, email);
        let response = await shopController.createPayseraPaymentRequest(model, userInfo?.token ?? "")
        if (response !== null) {
            switch (response.status){
                case MacroPaymentCreationStatus.OK:
                    window.location.href = response.redirectUrl;
                    break;
                case MacroPaymentCreationStatus.BAD_PRICE:
                    setError(t("unexpected_error"));
                    break;
                case MacroPaymentCreationStatus.BAD_EMAIL:
                    setEmail(undefined);
                    setError(t("bad_email_format"));
                    break;
                case MacroPaymentCreationStatus.CREATE_MACRO_PAYMENT_ERROR:
                    setError(t("unexpected_error"));
                    break;
                case MacroPaymentCreationStatus.PRODUCT_NOT_FOUND:
                    setError(t("unexpected_error"));
                    break;
                case MacroPaymentCreationStatus.CHANGED_EMAIL:
                    if (email && userInfo?.email !== email){
                        saveEmailToLocalStorage(email);
                    }
                    window.location.href = response.redirectUrl;
                    break;
                default:
                    setError(t("unexpected_error"));
                    break;
            }
        } else {
            console.error('Failed to create a paysera request.');
            setError(t("unexpected_error"));
        }
        setLoading(false);
    }

    return {
        createPayseraPaymentRequest,
        error
    }
}