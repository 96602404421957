export class HistoryThreadReview {
    public id: number;
    public userId: number;
    public postId: number;
    public eventTime: Date;
    public status: number;

    constructor(
        id: number,
        userId: number,
        postId: number,
        eventTime: Date,
        status: number) {
        this.id = id;
        this.userId = userId;
        this.postId = postId;
        this.eventTime = eventTime;
        this.status = status;
    }
}