import './Content.css';
import {ReactNode} from "react";

class ContentProps {
    children: ReactNode;
}

export const Content: React.FC<ContentProps> = ({ children })  => {
    return <div className={'content'}>
        {children}
    </div>
}