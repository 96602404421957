import "./Warning.css";
import {ReactNode} from "react";
import {ExclamationBounceIcon} from "../../../global/icons";

class ContentProps {
    children: ReactNode;
    style?: React.CSSProperties;
}

export const Warning: React.FC<ContentProps> = ({children, style}) => {
    return <div className={'delayed-warning'} style={style}>
        <div className={"warning-icon"}>
            <ExclamationBounceIcon style={{color: "black"}}/>
        </div>
        <div>
            {children}
        </div>
    </div>
}