import {useGlobalContext} from "../../../global/context/GlobalContext";
import "./ModalWindow.css";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

interface Props{
    children: JSX.Element;
}

export const ModalWindow = (props: Props) => {
    const {children} = props;
    const {setModalWindow} = useGlobalContext();

    return <div className={"modal-user-profile-wrapper"}>
    <div className={"modal-user-profile-background"}>
    <div className={"modal-user-profile-background-wrapper"}>
        {children}
        </div>
        </div>
        </div>
}