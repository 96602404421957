import './NavigationBar.css';
import {useTranslation} from 'react-i18next';
import {AboutIcon, BarsIcon, ForumIcon, HandIcon, HomeIcon, LoginIcon, VoteIcon} from "../../../global/icons";
import React, {useEffect} from "react";
import {NavigationBarExtrasPlay} from "../navigation-bar-extras/NavigationBarExtrasPlay";
import {NavigationBarExtrasDiscord} from "../navigation-bar-extras/NavigationBarExtrasDiscord";
import {
    ROUTER_ABOUT,
    ROUTER_FORUM,
    ROUTER_HOME,
    ROUTER_LOGIN,
    ROUTER_PROFILE,
    ROUTER_SHOP,
    ROUTER_VOTE
} from "../../../global/Constants";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import {Link} from "react-router-dom";
import {BUTTON_STYLE, StyledButton1} from "../button/Buttons";

export const NavigationBar = () => {
    const {t} = useTranslation();
    const {showTranslations, userInfo,setToggledMobile, handleNavigationChange,currentPath, setCurrentPath} = useGlobalContext();

    useEffect(() => {
        setCurrentPath(window.location.href.split('/')[3]?.toLowerCase());
    }, [])

    const renderMenuLink =(route: string, translation: string) => <Link to={route}
                                      className={'navigation-bar-category ' + (currentPath === route ? 'navigation-bar-category-active' : '')}
                                      onClick={() => handleNavigationChange(route)}>
        {/*getIconForNavigation(route)*/}
        {translation.toUpperCase()}
    </Link>

    const renderShopButton =(route: string, translation: string) => <Link to={route}
                                                                          onClick={() => handleNavigationChange(route)}
                                                                            style={{height:"100%", width:"calc(100% + 8px)", background:"unset"}}>
       <StyledButton1 children={<strong>{translation}</strong>} style={BUTTON_STYLE.STYLE_4}/>
    </Link>

    const getIconForNavigation = (route: string) => {
        switch (route) {
            case ROUTER_HOME:
                return <HomeIcon style={{fontSize: '30px', marginRight: '10px'}}/>
            case ROUTER_VOTE:
                return <VoteIcon style={{fontSize: '30px', marginRight: '10px'}}/>
            case ROUTER_ABOUT:
                return <AboutIcon style={{fontSize: '30px', marginRight: '10px'}}/>
            case ROUTER_FORUM:
                return <ForumIcon style={{fontSize: '30px', marginRight: '10px'}}/>
            default:
                return "";
        }
    }

    const RenderPcNavigation = () => <div className={'navigation-bar-container-pc'}
                                          style={{top: (showTranslations && (userInfo?.languagePreference ?? false) === false ? '45px' : '0px')}}>
        <div className={'navigation-bar-categories'}>
            {renderMenuLink(ROUTER_HOME, t('home'))}
            {renderMenuLink(ROUTER_VOTE, t('vote'))}
            {renderMenuLink(ROUTER_FORUM, t('forum'))}
            {renderShopButton(ROUTER_SHOP, t('store'))}
            {renderMenuLink(ROUTER_ABOUT, t('about'))}
        </div>
        <div className={'navigation-bar-categories'}>
            {userInfo ?
                <div className={"welcome-display"}>
                    <div>
                        {t("welcome")}
                        <div className={"wave-hand-content"}>
                            <div className={"wave-hand"}>
                                <HandIcon/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link to={`${ROUTER_PROFILE}/${userInfo.realName}`} onClick={() => handleNavigationChange(ROUTER_PROFILE)}>
                            <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}>
                            <span className={"username-span"}>
                                {userInfo.realName}
                            </span>
                            </StyledButton1>
                        </Link>
                        !
                    </div>
                </div>
                :
                <Link to={ROUTER_LOGIN}
                      onClick={() => handleNavigationChange(ROUTER_ABOUT)}>
                    <StyledButton1 style={BUTTON_STYLE.STYLE_2}>
                        <div className={"button-content"}>
                            {t('login')}
                            <LoginIcon/>
                        </div>
                    </StyledButton1>
                </Link>}
        </div>
    </div>

    const RenderMobileNavigation = () => <div className={'navigation-bar-container-phone'}
                                              style={{top: '0px'}}>
        <div className={'navigation-bar-categories'}>
            <div className={'navigation-bar-category'} onClick={() => setToggledMobile(true)}>
                <BarsIcon style={{fontSize: '30px'}}/>
            </div>
            <div className={'mobile-navigation-page-name'}>
                {GetNavigationName()}
            </div>
        </div>
    </div>

    const GetNavigationName = (): string => {
        switch (currentPath) {
            case ROUTER_HOME:
                return t('home').toUpperCase();
            case ROUTER_VOTE:
                return t('vote').toUpperCase();
            case ROUTER_ABOUT:
                return t('about').toUpperCase();
            case ROUTER_FORUM:
                return t('forum').toUpperCase();
            case ROUTER_PROFILE:
                return t('profile').toUpperCase();
            case ROUTER_SHOP:
                return t('store').toUpperCase();
            default:
                return "";
        }
    }

    return <React.Fragment>
        <div className={'navigation-bar-extras'}>
            <NavigationBarExtrasPlay/>
            <NavigationBarExtrasDiscord/>
        </div>
        <div style={{position: "absolute", top: "53px"}} id={"navigater"}></div>
        {RenderPcNavigation()}
        {RenderMobileNavigation()}
    </React.Fragment>
}