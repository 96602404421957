import "./ForumThreads.css";
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useForumThreads} from "./useForumThreads";
import {ContentLoader} from "../../../../global/display-components/content-loader/ContentLoader";
import React from "react";
import {useTranslation} from "react-i18next";
import {ResponseForumThreadPage} from "../../../../models/response/ResponseForumThreadPage";
import {BUTTON_STYLE, StyledButton1} from "../../../components/button/Buttons";
import {AddIcon, LeftIcon, LeftLeftIcon, RightIcon, RightRightIcon} from "../../../../global/icons";
import {AnimatedElementsOpacity} from "../../../components/animation/AnimatedElementsOpacity";
import {PageAction, ROUTER_NEW} from "../../../../global/Constants";
import {BackButton} from "../../../components/button/BackButton";
import {ThreadWithUserData} from "../../../../models/data/ThreadWithUserData";
import {ProfileImageWithFallback} from "../../../components/utils/ProfileImageWithFallback";
import {FormatDateFromISOString, FormatNumber} from "../../../../global/Utils";

export const ForumThreads = () => {
    const {id} = useParams();
    const parsedId = id ? parseInt(id) : NaN;
    const navigate = useNavigate();
    const {
        userInfo,
        currentPage,
        setCurrentPageWithLogic,
        responseForumThreadsPage,
        setCurrentPage
    } = useForumThreads(parsedId);
    const {t} = useTranslation();

    if (isNaN(parsedId)) {
        navigate("/error");
        return null;
    }

    if (responseForumThreadsPage === undefined) {
        return <div className={"text-content"}>
            <ContentLoader style={{height: "50vh", marginTop: "50px", minHeight: "700px"}}/>
        </div>
    }

    const pagesArray = () => {
        let lowerLimit = currentPage - 2;
        let higherLimit = currentPage + 2;
        let pages: number[] = [];
        let lowerLimitReached = false;
        let higherLimitReached = false;

        for (let i = 1; i <= (responseForumThreadsPage?.totalPageCount ?? 1); i++) {
            if (i < lowerLimit) {
                lowerLimitReached = true;
                continue;
            }
            if (i > higherLimit) {
                higherLimitReached = true;
                continue;
            }
            pages.push(i);
        }

        if (lowerLimitReached) {
            pages.unshift(-1);
        }
        if (higherLimitReached) {
            pages.push(-1);
        }

        return pages;
    }

    const displayForumThread = (forumThread: ThreadWithUserData, key: number) => <Link key={key}
                                                                                       to={forumThread.forumThread.id.toString()}>
        <StyledButton1 style={BUTTON_STYLE.STYLE_3}>
            <div className={"button-style-3-max-width"}>
                <div className={"button-style-3-image-container"}>
                    <ProfileImageWithFallback src={forumThread.userData.profilePictureUrl} alt={"Profile"}
                                              classes={"button-style-3-image"}/>
                </div>
                <div className={"forum-threads-replies-views-adaptable"}>
                    <div className={"button-style-3-text-container"}>
                        <span className={"title-uncolored"}>{forumThread.forumThread.title}</span>
                        <div className={"button-style-3-parameters"}>
                    <span>
                        {t("created_by")} {forumThread.userData.username}
                        <b style={{fontWeight: "normal", marginLeft: "10px"}}>
                            {FormatDateFromISOString(forumThread.forumThread.dateCreated.toString())}
                        </b>
                    </span>
                        </div>
                    </div>
                    <div className={"button-style-3-parameters forum-threads-replies-views"}>
                <span>
                    {t("replies")}: <b
                    style={{fontWeight: "normal"}}>{FormatNumber(forumThread.forumThread.replyCount)}</b>
                </span>
                        <span>
                    {t("views")}: <b
                            style={{fontWeight: "normal"}}>{FormatNumber(forumThread.forumThread.viewCount)}</b>
                </span>
                    </div>
                </div>
            </div>
        </StyledButton1>
    </Link>

    const renderCategoryTitle = (responseForumThreadsPage: ResponseForumThreadPage) =>
        <div
        className={'big-title no-margin forum-threads-category-title margin-top'}>
            <StyledButton1
                classes={"forum-threads-category-title"}
                children={<strong className={"forum-threads-category-title-strong"}>
                    <span className="emoji emoji-thread" role="img" aria-label="monkey"></span>
                    <div>
                        <div className={"forum-threads-category-title-container"}>
                            {responseForumThreadsPage.category.name}
                            <div className={"forum-threads-category-title-parameters"}>
                                <span>
                                    {t('threads')}: <b
                                    style={{fontWeight: "normal"}}>{responseForumThreadsPage.category.threadsCount}</b>
                                </span>
                                <span>
                                    {t('posts')}: <b
                                    style={{fontWeight: "normal"}}>{responseForumThreadsPage.category.postsCount}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                </strong>}
                style={BUTTON_STYLE.STYLE_4} />
    </div>

    const renderThreadPages = () => <div className={"forum-threads-pages"}>
        <div className={"forum-thread-page"} onClick={() => setCurrentPageWithLogic(PageAction.FIRST)}><LeftLeftIcon/>
        </div>
        <div className={"forum-thread-page"} onClick={() => setCurrentPageWithLogic(PageAction.BEFORE)}><LeftIcon/>
        </div>
        {pagesArray().map((pageNumber, i) => {
            if (pageNumber === -1) {
                return <div key={i}>...</div>;
            }
            if (pageNumber === currentPage) {
                return <div className={"forum-thread-page forum-thread-active-page"} key={i}>{pageNumber}</div>;
            }
            return <div className={"forum-thread-page"} key={i} onClick={() => {
                setCurrentPage(pageNumber)
            }}>{pageNumber}</div>;
        })}
        <div className={"forum-thread-page"} onClick={() => setCurrentPageWithLogic(PageAction.NEXT)}><RightIcon/></div>
        <div className={"forum-thread-page"} onClick={() => setCurrentPageWithLogic(PageAction.LAST)}><RightRightIcon/>
        </div>
    </div>

    return <div className={"text-content"}>
        <AnimatedElementsOpacity>
            <div className={"forum-threads-button-container"}>
                <BackButton/>
                {userInfo &&
                    <StyledButton1 style={BUTTON_STYLE.STYLE_2} classes={"button-style-2"}
                                   onClick={() => navigate(ROUTER_NEW)}>
                        <div className={"button-content"}>
                            {t("create")}
                            <AddIcon/>
                        </div>
                    </StyledButton1>}
            </div>
            {renderCategoryTitle(responseForumThreadsPage)}
            {responseForumThreadsPage.threadsWithUserData.length > 0 ?
                <AnimatedElementsOpacity>
                    {renderThreadPages()}
                    {responseForumThreadsPage.threadsWithUserData.map((x, key) => displayForumThread(x, key))}
                    {renderThreadPages()}
                </AnimatedElementsOpacity>
                :
                <AnimatedElementsOpacity>
                    <div className={"not-found-items text-white no-margin"}>{t("no_thread_yet")}</div>
                </AnimatedElementsOpacity>}
        </AnimatedElementsOpacity>
    </div>
}