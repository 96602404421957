import "./ForumCategories.css";
import {AnimatedElementsOpacity} from "../../../components/animation/AnimatedElementsOpacity";
import React from "react";
import {useTranslation} from "react-i18next";
import {BUTTON_STYLE, StyledButton1} from "../../../components/button/Buttons";
import {ForumRules} from "../../../../global/icons";
import {useForumCategories} from "./useForumCategories";
import {ResponseGroupedThreadCategory} from "../../../../models/response/ResponseGroupedThreadCategory";
import homeIcon from "../../../../resources/images/home-icon-only-letters.png";
import {ContentLoader} from "../../../../global/display-components/content-loader/ContentLoader";
import {Link, useNavigate} from "react-router-dom";
import {ROUTER_RULES} from "../../../../global/Constants";

export const ForumCategories = () => {
    const {t} = useTranslation();
    const {forumCategories} = useForumCategories();
    const navigate = useNavigate();

    const displayThreadCategory = (category: ResponseGroupedThreadCategory, key: number) => {
        return <div key={key}>
            <StyledButton1
                classes={"forum-category-title"}
                children={<strong className={"forum-category-title-strong"}>
                    <span className="emoji emoji-thread" role="img" aria-label="monkey"></span>
                    {category.name}
                </strong>}
                style={BUTTON_STYLE.STYLE_4}/>
            <div className={"forum-category-select-container"}>
                {category.threadCategories.map(x =>
                    <Link to={x.id.toString()}>
                        <StyledButton1 style={BUTTON_STYLE.STYLE_3}>
                            <div className={"button-style-3-max-width"}>
                                <div className={"button-style-3-image-container"}>
                                    <div className={"button-style-3-image"}
                                         style={{backgroundImage: `url(${homeIcon})`}}></div>
                                </div>
                                <div className={"forum-threads-replies-views-adaptable"}>
                                    <div className={"button-style-3-text-container"}>
                                        <span className={"title-uncolored"}>{x.name}</span>
                                        <div className={"button-style-3-parameters forum-category-threads-posts"}>
                                <span>
                                    {t('threads')}: <b style={{fontWeight: "normal"}}>{x.threadsCount}</b>
                                </span>
                                            <span>
                                        {t('posts')}: <b style={{fontWeight: "normal"}}>{x.postsCount}</b>
                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </StyledButton1>
                    </Link>
                )}
            </div>
        </div>
    }

    return <AnimatedElementsOpacity>
        <div className={'text-content'}>
            <div className={'big-title'}>
                <span className="emoji emoji-vote" role="img" aria-label="monkey"></span>
                {t("forum_rules_explanation_1")}!
            </div>
            <StyledButton1 style={BUTTON_STYLE.STYLE_2} classes={"button-style-2-secondary"}
                           onClick={() => navigate(ROUTER_RULES)}>
                <div className={"button-content"}>
                    {t('forum_rules')}
                    <ForumRules/>
                </div>
            </StyledButton1>
            {forumCategories !== null ?
                <div className={"forum-categories-content"}>
                    {forumCategories?.map((x, key) => displayThreadCategory(x, key))}
                </div>
                : <ContentLoader style={{height: "50vh", marginTop: "50px", minHeight: "700px"}}/>}
        </div>
    </AnimatedElementsOpacity>
}