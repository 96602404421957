import {BUTTON_STYLE, StyledButton1} from "../../components/button/Buttons";
import {LanguageIcon, LogoutIcon, PostsIcon, ThumbsDownIcon, ThumbsUpIcon} from "../../../global/icons";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import {ProfileImageWithFallback} from "../../components/utils/ProfileImageWithFallback";
import {UserTags} from "../../components/utils/UserTags";
import {FormatDateFromISOString} from "../../../global/Utils";
import "./Profile.css";
import {useProfile} from "./useProfile";
import {useNavigate, useParams} from "react-router-dom";
import {ContentLoader} from "../../../global/display-components/content-loader/ContentLoader";
import Resizer from 'react-image-file-resizer';
import {BackButton} from "../../components/button/BackButton";
import {UserStatistics} from "../../components/utils/UserStatistics";

export const Profile = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const {logOut, changeLanguagePreference} = useGlobalContext();
    const {responseGetUserProfileInfo,userInfo, selectedFile , saveUserProfilePicture} = useProfile(id ?? "");
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);

    if (responseGetUserProfileInfo === null || !id){
        navigate("/error");
        return null;
    }

    if (responseGetUserProfileInfo === undefined){
        return <div className={"text-content"}>
            <ContentLoader style={{height: "50vh", minHeight: "700px"}}/>
        </div>
    }

    // Function to handle file selection
    const  handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            await resizeAndConvertToBase64(file);
        }
    };

    // Function to resize the selected file
    const resizeAndConvertToBase64 = async (file: File) => {
        Resizer.imageFileResizer(
            file,
            400, // maxWidth
            400, // maxHeight
            'JPEG', // compressFormat
            80, // quality
            0, // rotation
            (uri) => {
                // uri is the resized image as a data URL
                saveUserProfilePicture(uri as string);
            },
            'base64' // outputType
        );
    };

    const handlePhotoChange =()=>{
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the click event on the file input
        }
    }

    return <React.Fragment>
    <div className={"text-content"}>
        <div className={"profile-option-buttons-display"}>
            {userInfo?.realName === (id ?? "") ? <React.Fragment>
                <StyledButton1 style={BUTTON_STYLE.STYLE_2} classes={"button-style-2-secondary"}
                               onClick={logOut}>
                    <div className={"button-content"}>
                        <LogoutIcon/>
                        {t("logout")}
                    </div>
                </StyledButton1>
                <StyledButton1 style={BUTTON_STYLE.STYLE_2} classes={"button-style-2"}
                               onClick={() => changeLanguagePreference()}>
                    <div className={"button-content"}>
                        {t("change_language")}
                        <LanguageIcon/>
                    </div>
                </StyledButton1>
            </React.Fragment>
            :
            <BackButton />}
        </div>

        <div className={"profile-container"}>
            <div className={"big-title no-margin profile-username"}>
                {responseGetUserProfileInfo.userDto.realName}
            </div>
            <div className={"profile-info"}>
                <div className={"profile-parameters-container"}>
                    <UserTags userData={responseGetUserProfileInfo.userData}/>
                    <UserStatistics userData={responseGetUserProfileInfo.userData} className={"profile-text"} />
                    <div className={"forum-profile-text"} style={{marginTop: "20px"}}>
                        {t("joined")} {FormatDateFromISOString(responseGetUserProfileInfo.userData.dateTimeCreated.toString())}
                    </div>
                </div>
                <div className={"profile-picture-container"} onClick={handlePhotoChange}>
                    <div className={`profile-picture ${id?.toLowerCase() === userInfo?.realName?.toLowerCase()? "active" : ""}`}>
                        <div className={"profile-picture-change"}>
                            <div>
                                {t("change")}
                            </div>
                        </div>
                        <input type="file" accept="image/jpeg, image/png" onChange={handleFileSelect} style={{display:"none"}} ref={fileInputRef} />
                        <ProfileImageWithFallback
                            src={selectedFile}
                            alt="Profile"
                            style={{width: '200px', height: "200px", maxWidth: '200px', maxHeight: "200px", objectFit: "cover"}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </React.Fragment>
}