import {useGlobalContext} from "../../../global/context/GlobalContext";
import {GlobalBuilder} from "../../../global/GlobalBuilder";
import {useEffect, useState} from "react";
import {ResponseGetShopCategoryWithItems} from "../../../models/response/ResponseGetShopCategoryWithItems";
import {UserInfo} from "../../../models/data/UserInfo";
import {ShopItemDescription} from "../shop-item-description/ShopItemDescription";
import React from 'react';
import {ResponseGetShopItemWithDescription} from "../../../models/response/ResponseGetShopItemWithDescription";

interface ExportProps{
    shopCategoryWithItems : ResponseGetShopCategoryWithItems | null;
    userInfo: UserInfo | undefined;
    getItemWithDescription: (itemId: number) => Promise<void>;
    setModalWindow: (modal: JSX.Element | null) => void;
    itemModalData: ResponseGetShopItemWithDescription | null;
    setItemModalData: (response: ResponseGetShopItemWithDescription | null) => void;
}

export const useShopCategory = (categoryId:number) : ExportProps => {
    const {userInfo,setModalWindow, setLoading} = useGlobalContext();
    const shopController = GlobalBuilder.getInstance().getShopController();
    const [shopCategoryWithItems, setShopCategoryWithItems] = useState<ResponseGetShopCategoryWithItems | null>(null);
    const [itemModalData, setItemModalData] = useState<ResponseGetShopItemWithDescription | null>(null)

    useEffect(() => {
        if (userInfo !== undefined){
            getAllShopCategories();
        }
    }, [userInfo])

    const getAllShopCategories = async () => {
        let response = await shopController.getAllCategoryItems(categoryId, userInfo?.token ?? "")
        if (response !== null) {
            setShopCategoryWithItems(response);
        } else {
            console.error('Failed to retrieve shop categories.');
        }
    }

    const getItemWithDescription = async (itemId : number) => {
        setLoading(true);
        let response = await shopController.getItemWithDescription(itemId, userInfo?.token ?? "")
        if (response !== null) {
            setItemModalData(response);
        } else {
            console.error('Failed to retrieve shop categories.');
        }
        setLoading(false);
    }

    return {
        shopCategoryWithItems,
        userInfo,
        getItemWithDescription,
        setModalWindow,
        itemModalData,
        setItemModalData
    }
}