import {AboutIcon, CloseIcon, ForumIcon, HandIcon, HomeIcon, LoginIcon, VoteIcon} from "../../../global/icons";
import {Link} from "react-router-dom";
import {
    ROUTER_ABOUT,
    ROUTER_FORUM,
    ROUTER_HOME,
    ROUTER_LOGIN,
    ROUTER_PROFILE, ROUTER_SHOP,
    ROUTER_VOTE
} from "../../../global/Constants";
import LanguageSwitcher from "../../components/language-switcher/LanguageSwitcher";
import React, {useState} from "react";
import homeIcon from "../../../resources/images/home-icon-only-letters.png";
import {useTranslation} from "react-i18next";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import "./MobileNavigationContent.css";
import {BUTTON_STYLE, StyledButton1} from "../../components/button/Buttons";
import homeBackground from '../../../resources/images/home-background-2.jpg'

export const MobileNavigationContent = () => {
    const {t} = useTranslation();
    const {setToggledMobile, handleNavigationChange, currentPath, userInfo} = useGlobalContext();
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const icon = () => <div className={'background-icon-container-mobile'}>
        <div className={'background-icon-mobile'} style={{backgroundImage: `url(${homeIcon})`}}></div>
    </div>;

    const animateClosing = (path: string) => {
        setIsClosing(true);
        setTimeout(() => {
            handleNavigationChange(path, true);
        }, 50);
    }

    const handleClosing = () => {
        setIsClosing(true);
        setTimeout(() => {
            setToggledMobile(false)
        }, 100);
    }

    const backgroundImage = () => <div className={'background-image-mobile'}
                                       style={{backgroundImage: `url(${homeBackground})`}}></div>;

    const renderLoginOrUsername = () => <div className={'navigation-bar-categories'}>
        {userInfo ?
            <div className={"welcome-display-mobile"}>
                <div>
                    {t("welcome")}
                    <div className={"wave-hand-content"}>
                        <div className={"wave-hand"}>
                            <HandIcon/>
                        </div>
                    </div>
                </div>
                <div>
                    <Link to={`${ROUTER_PROFILE}/${userInfo.realName}`} onClick={() => animateClosing(ROUTER_PROFILE)}>
                        <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}>
                            <span className={"username-span"}>
                                {userInfo.realName}
                            </span>
                        </StyledButton1>
                    </Link>
                    !
                </div>
            </div>
            :
            <Link to={ROUTER_LOGIN}
                  onClick={() => animateClosing(ROUTER_LOGIN)}>
                <StyledButton1 style={BUTTON_STYLE.STYLE_2}>
                    <div className={"button-content"}>
                        {t('login')}
                        <LoginIcon/>
                    </div>
                </StyledButton1>
            </Link>}
    </div>

    const renderNavigation = () => <React.Fragment>
        {renderShopButton(ROUTER_SHOP, t('store'))}
        {renderMenuLink(ROUTER_HOME, t('home'))}
        {renderMenuLink(ROUTER_VOTE, t('vote'))}
        {renderMenuLink(ROUTER_FORUM, t('forum'))}
        {renderMenuLink(ROUTER_ABOUT, t('about'))}
    </React.Fragment>

    const renderMenuLink = (route: string, translation: string) => <Link to={route}
                                                                         className={'navigation-bar-category mobile ' + (currentPath === route ? 'navigation-bar-category-active-mobile' : '')}
                                                                         onClick={() => animateClosing(route)}>
        {/*getIconForNavigation(route)*/}
        {translation.toUpperCase()}
    </Link>

    const renderShopButton =(route: string, translation: string) => <Link to={route}
                                                                          onClick={() => animateClosing(route)}
                                                                          style={{height:"100px", width:"50%", background:"unset", marginTop:"30px"}}>
        <StyledButton1 children={<strong>{translation}</strong>} style={BUTTON_STYLE.STYLE_4}/>
    </Link>

    const getIconForNavigation = (route: string) => {
        switch (route) {
            case ROUTER_HOME:
                return <HomeIcon style={{fontSize: '30px', marginRight: '10px'}}/>
            case ROUTER_VOTE:
                return <VoteIcon style={{fontSize: '30px', marginRight: '10px'}}/>
            case ROUTER_ABOUT:
                return <AboutIcon style={{fontSize: '30px', marginRight: '10px'}}/>
            case ROUTER_FORUM:
                return <ForumIcon style={{fontSize: '30px', marginRight: '10px'}}/>
            default:
                return "";
        }
    }

    return <div
        className={`mobile-navigation-content ${isClosing ? "closing" : ""}`}>
        <div className={"mobile-navigation-content-container"}>
            <div className={"mobile-menu-background"}>
                {backgroundImage()}
                <div className={"mobile-navigation-content-container-element"}>
                    <div className={"navigation-mobile-close-button"}>
                        <div onClick={handleClosing}>
                            <CloseIcon/>
                        </div>
                    </div>
                    {icon()}
                    {renderLoginOrUsername()}
                    {renderNavigation()}
                </div>
                {(userInfo?.languagePreference ?? false) === false &&
                    <div className={"language-select-mobile"}>
                        {t("select_language")}
                        <LanguageSwitcher mobile={true}/>
                    </div>
                }
            </div>
        </div>
    </div>
}