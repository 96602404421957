import React, {createContext} from 'react';
import './global/Global.css';
import './i18n'; // Initialize i18n
import {GlobalContextProps, GlobalProvider} from './global/context/GlobalContext';
import {BrowserRouter as Router} from "react-router-dom";
import {AppContent} from "./displays/views/app/App/AppContent";


export const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

function App() {
    return (
        <Router>
        <GlobalProvider>
            <AppContent />
        </GlobalProvider>
        </Router>
    );
}

export default App;
