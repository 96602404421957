import FlagLt from "../../../resources/images/Flag_lt.svg";
import FlagLv from "../../../resources/images/Flag_lv.svg";
import FlagEe from "../../../resources/images/Flag_ee.svg";
import React, {useEffect, useState} from "react";
import "./BalticFlags.css";

const flags = [FlagLt, FlagLv, FlagEe];

export const BalticFlags = () => {
    const [currentFlagIndex, setCurrentFlagIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Your function logic here
            setCurrentFlagIndex((prevIndex) => (prevIndex + 1) % flags.length);
        }, 500);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return <img src={flags[currentFlagIndex]} alt={"flag"} className={"baltic-flags-emoji"}/>
}