import './Background.css';
import homeBackground from '../../../resources/images/home-background-2.jpg'
import homeIcon from '../../../resources/images/home-icon.png'
import homeIconOnlyLetters from '../../../resources/images/home-icon-only-letters.png'
import React, {ReactNode} from "react";
import LanguageSwitcher from "../language-switcher/LanguageSwitcher";
import {useGlobalContext} from "../../../global/context/GlobalContext";

interface BackgroundProps {
    children: ReactNode;
    isSpecialPage: boolean;
}

export const Background: React.FC<BackgroundProps> = (props: BackgroundProps) => {
    const {children, isSpecialPage} = props;
    const {loading,userInfo} = useGlobalContext();

    const backgroundImage = () => <div className={'background-image'}
                                       style={{backgroundImage: `url(${homeBackground})`}}></div>;

    const icon = () => <div className={'background-icon-container'}>
        <div className={'background-icon'} style={{backgroundImage: `url(${homeIcon})`}}></div>
    </div>;

    const iconLetters = () => <div className={'background-icon-only-letter-container'}>
        <div className={'background-icon-only-letter'} style={{backgroundImage: `url(${homeIconOnlyLetters})`}}></div>
    </div>;

    const renderNormalPage= () => <div className={`background-view ${loading ? "loading" : ""}`}>
        {backgroundImage()}
        <div className={'background'}>
            {!isSpecialPage && (userInfo?.languagePreference ?? false) === false &&
                <LanguageSwitcher />}
            {icon()}
            {children}
        </div>
    </div>

    const renderLoginPage = () => <div className={`background-view ${loading ? "loading" : ""}`}>
        {backgroundImage()}
        <div className={'background-icon-only'}>
            {iconLetters()}
            {children}
        </div>
    </div>

    return isSpecialPage ? renderLoginPage() : renderNormalPage()
}