import "./ImageGallery.css";
import React, {useEffect, useState} from "react";

interface Props{
    contents: React.ReactNode[];
    images: string[];
}

export const ImageGallery = (props: Props) => {
    const {contents, images} = props;
    const [counter, setCounter] = useState(1);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (counter + 1 > contents.length) {
                setCounter(1);
            } else {
                setCounter(counter + 1);
            }
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [counter]);

    return <section className={"image-gallery-section"}>
        <div className={"image-gallery-slider"}>
            <div className={"image-gallery-slide"}>
                {contents.map((x, index) =><input type={"radio"} name={"radio-btn"} id={`radio${index + 1}`} checked={counter === index + 1} /> )}
                {contents.map((x, index) => <div className={"image-gallery-st-slide"} style={{backgroundImage: `url(${images[index]})`, marginLeft: index === 0 ?  `-${(counter - 1) * 20}%` : 'unset'}}>
                        {x}
                    </div>)}
                <div className={"nav-auto"}>
                    {contents.map((x, index) => <div className={`a-b${index + 1}`} style={{background: counter === index + 1 ? "black" : "transparent"}} onClick={() => setCounter(index + 1)}></div>)}
                </div>
            </div>
        </div>
    </section>
}