export class RequestCreateThread {
   public categoryId :number;
   public title: string;
   public description: string;

    constructor(categoryId: number, title: string, description: string) {
        this.categoryId = categoryId;
        this.title = title;
        this.description=description;
    }

}