import {ExtraDataController} from "../controllers/ExtraDataController";
import {AjaxHelper} from "../helpers/AjaxHelper";
import {AuthenticationController} from "../controllers/AuthenticationController";
import {UserController} from "../controllers/UserController";
import {ForumController} from "../controllers/ForumController";
import {ShopController} from "../controllers/ShopController";

export class GlobalBuilder {
    private static instance: GlobalBuilder;

    // Singleton constructor
    private constructor() {
        // Initialization code, if any
    }

    // Get the singleton instance
    public static getInstance(): GlobalBuilder {
        if (!GlobalBuilder.instance) {
            GlobalBuilder.instance = new GlobalBuilder();
        }
        return GlobalBuilder.instance;
    }

    // Add your methods, repositories, services, etc. here

    // Example method
    public getExtraDataController(): ExtraDataController {
        return new ExtraDataController(new AjaxHelper());
    }

    public getAuthenticationController(): AuthenticationController {
        return new AuthenticationController(new AjaxHelper());
    }

    public getUserController(): UserController {
        return new UserController(new AjaxHelper());
    }

    public getForumController(): ForumController{
        return new ForumController(new AjaxHelper());
    }

    public getShopController(): ShopController{
        return new ShopController(new AjaxHelper());
    }
}
