import {BUTTON_STYLE, StyledButton1} from "./Buttons";
import {BackIcon} from "../../../global/icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const BackButton = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return <StyledButton1 style={BUTTON_STYLE.STYLE_2} classes={"button-style-2-secondary"}
                          onClick={() => navigate(-1)}>
        <div className={"button-content"}>
            <BackIcon/>
            {t("back")}
        </div>
    </StyledButton1>
}