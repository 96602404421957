import {AjaxHelper} from "../helpers/AjaxHelper";
import {API_URL} from "../global/Constants";
import {ResponseGroupedThreadCategory} from "../models/response/ResponseGroupedThreadCategory";
import {ResponseForumThreadPage} from "../models/response/ResponseForumThreadPage";
import {ResponseCreateThread} from "../models/response/ReponseCreateThread";
import {RequestCreateThread} from "../models/request/RequestCreateThread";
import {ResponseGetThreadPage} from "../models/response/ResponseGetThreadPage";
import {RequestCreatePost} from "../models/request/RequestCreatePost";
import {ResponseCreatePost} from "../models/response/ResponseCreatePost";
import {RequestReviewPost} from "../models/request/RequestReviewPost";
import {Success} from "../models/response/Success";
import {ResponseReviewPost} from "../models/response/ResponseReviewPost";

export class ForumController {
    private readonly ajaxHelper: AjaxHelper;

    constructor(ajaxHelper: AjaxHelper) {
        this.ajaxHelper = ajaxHelper;
    }

    async getForumCategories(token: string): Promise<ResponseGroupedThreadCategory[] | null> {
        try {
            const url = `${API_URL}/api/Forum/get_thread_categories`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };
            return await this.ajaxHelper.ajaxGet<ResponseGroupedThreadCategory[]>(url, headers);
        } catch (error: any) {
            console.error(`Error in getForumCategories: ${error.message}`);
        }
        return null;
    }

    async getForumThreadPage(categoryId:number, page: number, token: string): Promise<ResponseForumThreadPage | null> {
        try {
            const url = `${API_URL}/api/Forum/get_forum_thread_page?categoryId=${categoryId}&page=${page}`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };
            return await this.ajaxHelper.ajaxGet<ResponseForumThreadPage>(url, headers);
        } catch (error: any) {
            console.error(`Error in getForumThreadPage: ${error.message}`);
        }
        return null;
    }

    async createThreadAsync(createThread : RequestCreateThread, token: string): Promise<ResponseCreateThread | null> {
        try {
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };
            const url = `${API_URL}/api/Forum/create_thread`;
            return await this.ajaxHelper.ajaxPost<ResponseCreateThread>(url, createThread, headers)
        } catch (error : any) {
            console.error(`Error in createThreadAsync: ${error.message}`);
        }
        return null;
    }

    async getThreadPage(threadId:number, page: number, token: string): Promise<ResponseGetThreadPage | null> {
        try {
            const url = `${API_URL}/api/Forum/get_thread_page?threadId=${threadId}&page=${page}`;
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };
            return await this.ajaxHelper.ajaxGet<ResponseGetThreadPage>(url, headers);
        } catch (error: any) {
            console.error(`Error in getThreadPage: ${error.message}`);
        }
        return null;
    }

    async createPostAsync(createPost : RequestCreatePost, token: string): Promise<ResponseCreatePost | null> {
        try {
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };
            const url = `${API_URL}/api/Forum/create_post`;
            return await this.ajaxHelper.ajaxPost<ResponseCreatePost>(url, createPost, headers)
        } catch (error : any) {
            console.error(`Error in createPostAsync: ${error.message}`);
        }
        return null;
    }

    async reviewPostAsync(reviewPost : RequestReviewPost, token: string): Promise<ResponseReviewPost | null> {
        try {
            const headers: Record<string, string> = {
                'Authorization': `${token}`,
            };
            const url = `${API_URL}/api/Forum/review_post`;
            return await this.ajaxHelper.ajaxPost<ResponseReviewPost>(url, reviewPost, headers);
        } catch (error : any) {
        }
        return null;
    }
}