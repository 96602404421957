import React, { useContext, useState, ReactNode, useEffect } from 'react';
import { GlobalBuilder } from '../GlobalBuilder';
import {GlobalContext} from "../../App";
import {UserInfo} from "../../models/data/UserInfo";
import {useTranslation} from "react-i18next";


export const useGlobalContext = (): GlobalContextProps => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }
    return context;
};

interface GlobalProviderProps {
    children: ReactNode;
}

export interface GlobalContextProps {
    playerOnlineCount: number | undefined;
    playerDiscordCount: number | undefined;
    showTranslations: boolean;
    setShowTranslations: (showTranslations: boolean) => void;
    selectedLanguage: string;
    changeLanguage: (selectedLanguage: string) => void;
    loginAsync(username: string, password: string): Promise<boolean>;
    userInfo: UserInfo | undefined;
    loading : boolean;
    setLoading: (loading: boolean) => void;
    setUserInfo: (userInfo: any) => void;
    toggledMobile: boolean;
    setToggledMobile: (toggled : boolean) => void;
    handleNavigationChange: (path: string, close?: boolean) => void;
    currentPath: string;
    setCurrentPath: (currentPath: string) => void;
    logOut : () => void;
    saveLanguagePreferenceToLocalStorage : (languagePreference : string) => void;
    changeLanguagePreference: () => void;
    setModalWindow: (modal: JSX.Element | null) => void;
    modalWindow : JSX.Element | null;
    saveEmailToLocalStorage: (email:string) => void;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
    const {i18n} = useTranslation();
    const [playerOnlineCount, setPlayerOnlineCount] = useState<number | undefined>(undefined);
    const [playerDiscordCount, setPlayerDiscordCount] = useState<number | undefined>(undefined);
    const [showTranslations, setShowTranslations] = useState<boolean>(true);
    const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [toggledMobile, setToggledMobile] = useState(false);
    const [currentPath, setCurrentPath] = useState<string>('');
    const [modalWindow, setModalWindow] = useState<JSX.Element | null>(null);

    const extraDataController = GlobalBuilder.getInstance().getExtraDataController();
    const authenticationController = GlobalBuilder.getInstance().getAuthenticationController();

    useEffect(() => {
        getLocalStorageLogin();

        extraDataController.getMinecraftPlayerCountAsync().then((playerCount) => {
            if (playerCount !== null) {
                setPlayerOnlineCount(playerCount);
            } else {
                console.error('Failed to retrieve player count.');
            }
        });

        extraDataController.getDiscordOnlineMembersCountAsync().then((memberCount) => {
            if (memberCount !== null) {
                setPlayerDiscordCount(memberCount);
            } else {
                console.error('Failed to retrieve player count.');
            }
        });
    }, []);

    useEffect(()=> {
        if (userInfo){
            i18n.changeLanguage(userInfo?.languagePreference ?? "en");
            setSelectedLanguage(userInfo?.languagePreference ?? "en");
        }
    }, [userInfo])

    const getLocalStorageLogin = () => {
        const loggedStorageLoginData = localStorage.getItem('mcBaltics');
        if (loggedStorageLoginData && loggedStorageLoginData !== "undefined"){
            let parsedModel = JSON.parse(loggedStorageLoginData);
            if (parsedModel){
                setUserInfo(parsedModel);
                //setUserInfo(undefined);
            }
        }
    }

    const loginAsync = async (username: string, password: string) => {
        let response = await authenticationController.loginAsync(username, password)
        if (response !== null) {
            let newUserInfo = new UserInfo(response.token, response.realName, response.firstTime, response.languagePreference, response.email);
            setUserInfo(newUserInfo);
            //setUserInfo(undefined);
            localStorage.setItem('mcBaltics', JSON.stringify(newUserInfo));
            return true;
        } else {
            console.error('Failed to retrieve player count.');
        }
        return false;
    }

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
    };

    const handleNavigationChange = (path: string, close: boolean = false) => {
        setCurrentPath(path);
        if (close) {
            setToggledMobile(false);
        }
        // TODO scroll top
    }

    const logOut = () => {
        localStorage.removeItem('mcBaltics');
        setUserInfo(undefined);
    }

    const saveLanguagePreferenceToLocalStorage =(languagePreference:string)=>{
        if (!userInfo)
        {
            return;
        }
        let newUserInfo = new UserInfo(
            userInfo.token,
            userInfo.realName,
            false,
            languagePreference,
            userInfo.email
        )
        localStorage.setItem('mcBaltics', JSON.stringify(newUserInfo));
    }

    const saveEmailToLocalStorage =(email:string)=>{
        if (!userInfo)
        {
            return;
        }
        let newUserInfo = new UserInfo(
            userInfo.token,
            userInfo.realName,
            false,
            userInfo.languagePreference,
            email
        )
        localStorage.setItem('mcBaltics', JSON.stringify(newUserInfo));
    }

    const changeLanguagePreference =()=>{
        setUserInfo(prevUserInfo => {
            if (!prevUserInfo) return prevUserInfo; // return undefined if prevUserInfo is undefined
            return {
                ...prevUserInfo,
                firstTime: true,
            };
        });
    }

    const contextValue2 = {
        playerOnlineCount,
        playerDiscordCount,
        showTranslations,
        setShowTranslations,
        selectedLanguage,
        changeLanguage,
        loginAsync,
        userInfo,
        loading,
        setLoading,
        setUserInfo,
        toggledMobile,
        setToggledMobile,
        handleNavigationChange,
        currentPath,
        setCurrentPath,
        logOut,
        saveLanguagePreferenceToLocalStorage,
        changeLanguagePreference,
        setModalWindow,
        modalWindow,
        saveEmailToLocalStorage
    };

    return <GlobalContext.Provider value={contextValue2}>{children}</GlobalContext.Provider>;
};
