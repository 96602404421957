import "./Wiki.css";
import {Warning} from "../../components/content/Warning";
import {useTranslation} from "react-i18next";
import React from "react";

export const Wiki = () => {
    const {t} = useTranslation();

    return <div className={"text-content"}>
        <div className={"big-title no-margin"}>
            <span className="emoji emoji-wiki" role="img" aria-label="monkey"></span>
            Wiki</div>
         <Warning style={{marginTop: "40px"}}>
        <div className={'title'}>{t("important_note")}:</div>
        <div className={'text'}>{t("this_page_is_coming_soon")}!</div>
        </Warning>
    </div>
}