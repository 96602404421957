import {UserData} from "../../../models/data/UserData";
import {UserTypes} from "../../../global/Constants";
import {useTranslation} from "react-i18next";
import "./UserTags.css";
import React from "react";
import FlagLt from "../../../resources/images/Flag_lt.svg";
import FlagLv from "../../../resources/images/Flag_lv.svg";
import FlagEe from "../../../resources/images/Flag_ee.svg";
import FlagUk from "../../../resources/images/Flag_uk.svg";

interface Props{
    userData: UserData;
}

export const UserTags = (props: Props) => {
    const{userData} = props;
    const {t} = useTranslation();

    let tags = [];

    switch (userData.userType) {
        case UserTypes.Player:
            tags.push(<div key={10} className={"user-tag"} style={{background: "#540404"}}>{t("player")}</div>)
            break;
        default:
            break;
    }

    switch(userData.languagePreference){
        case "lt":
            tags.push(<div key={20} className={"user-tag"} style={{background: "#51544f"}}>LT <img src={FlagLt} alt={"flag"} className={"user-tag-flag"}/></div>)
            break;
        case "lv":
            tags.push(<div key={21} className={"user-tag"} style={{background: "#51544f"}}>LV <img src={FlagLt} alt={"flag"} className={"user-tag-flag"}/></div>)
            break;
        case "ee":
            tags.push(<div key={22} className={"user-tag"} style={{background: "#51544f"}}>EE <img src={FlagEe} alt={"flag"} className={"user-tag-flag"}/></div>)
            break;
        case "en":
            tags.push(<div key={23} className={"user-tag"} style={{background: "#51544f"}}>EN <img src={FlagUk} alt={"flag"} className={"user-tag-flag"}/></div>)
            break;
        default:
            break;
    }


    return <div className={"user-tag-container"}>
        {tags.map(x => x)}
    </div>
}