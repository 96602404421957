import React, { useRef } from 'react';
import { useSpring, animated } from 'react-spring';

interface AnimatedElementProps {
    children: React.ReactNode;
}

export const AnimatedElementsFromBottom: React.FC<AnimatedElementProps> = ({ children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, setVisible] = React.useState(false);

    const props = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
    });

    const onScroll = () => {
        if (ref.current) {
            const top = ref.current.getBoundingClientRect().top;
            setVisible(top < window.innerHeight);
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', onScroll, true);
        onScroll(); // Check visibility on mount

        return () => window.removeEventListener('scroll', onScroll, true);
    }, []);

    return (
        <animated.div ref={ref} style={props}>
            {children}
        </animated.div>
    );
};
