import {GlobalBuilder} from "../../../global/GlobalBuilder";
import {useEffect, useState} from "react";
import {useGlobalContext} from "../../../global/context/GlobalContext";
import {ResponseGetUserProfileInfo} from "../../../models/response/ResponseGetUserProfileInfo";
import {UserInfo} from "../../../models/data/UserInfo";
import { saveAs } from 'file-saver';
import {RequestUpdateUserData} from "../../../models/request/RequestUpdateUserData";

interface ExportProps {
    responseGetUserProfileInfo: ResponseGetUserProfileInfo | null | undefined;
    userInfo: UserInfo | undefined;
    saveUserProfilePicture: (base64photo: string) => Promise<boolean>;
    selectedFile: string | undefined | null;
}

export const useProfile = (username: string): ExportProps => {
    const userController = GlobalBuilder.getInstance().getUserController();
    const {userInfo,setLoading} = useGlobalContext();
    const [responseGetUserProfileInfo, setResponseGetUserProfileInfo] = useState<ResponseGetUserProfileInfo | null | undefined>(undefined);
    const [selectedFile, setSelectedFile] = useState<string | undefined | null>(undefined);


    useEffect(() => {
        if (username === undefined) {
            setResponseGetUserProfileInfo(null);
        } else {
            getUserProfile(username);
        }
    }, [userInfo]);

    const getUserProfile = async (username: string) => {
        try {
            let response = await userController.getUserProfileInfo(username, userInfo?.token ?? "");
            if (response) {
                setResponseGetUserProfileInfo(response);
                setSelectedFile(response.userData.profilePictureUrl)
            }
        } catch (error) {
            console.error('Error profile setup:', error);
        }
    }

    const saveUserProfilePicture = async (base64photo: string) => {
        try {
            setLoading(true);
            let model = new RequestUpdateUserData();
            model.setProfilePicuteUrl(base64photo);

            // Pass selectedLanguage and token directly to updateUserData
            let response = await userController.updateUserData(model, userInfo?.token ?? "");

            if (response) {
                setSelectedFile(base64photo);
                setLoading(false);
                return true;
            }
        } catch (error) {
            console.error('Error saveUserFirstTimeProfile:', error);
        }

        setLoading(false);
        return false;
    }

    return {
        responseGetUserProfileInfo,
        userInfo,
        selectedFile,
        saveUserProfilePicture
    }
}