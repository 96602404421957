import {AjaxHelper} from "../helpers/AjaxHelper";
import {API_URL, PLAY_SERVER_LINK, PLAY_SERVER_PORT} from "../global/Constants";

export class ExtraDataController{
    private readonly ajaxHelper : AjaxHelper;

    constructor(ajaxHelper : AjaxHelper) {
        this.ajaxHelper = ajaxHelper;
    }

    async getMinecraftPlayerCountAsync(): Promise<number | null> {
        try {
            const url = `${API_URL}/api/Metrics/get_server_player_count`;
            return await this.ajaxHelper.ajaxGet<number>(url) as number | null;
        } catch (error : any) {
            console.error(`Error in getMinecraftPlayerCount: ${error.message}`);
        }
        return null;
    }

    async getDiscordOnlineMembersCountAsync(): Promise<number | null> {
        try {
            const url = `${API_URL}/api/Metrics/get_discord_online_count`;
            return await this.ajaxHelper.ajaxGet<number>(url) as number | null;
        } catch (error : any) {
            console.error(`Error in getMinecraftPlayerCount: ${error.message}`);
        }
        return null;
    }
}