export class UserInfo {
    readonly token: string;
    readonly realName: string;
    readonly firstTime: boolean;
    readonly languagePreference: string | undefined;
    readonly email: string | undefined;

    constructor(token: string, realName: string, firstTime: boolean, languagePreference: string | undefined, email: string | undefined) {
        this.token = token;
        this.realName = realName;
        this.firstTime = firstTime;
        this.languagePreference = languagePreference;
        this.email = email;
    }
}