import './Home.css';
import React from "react";
import {useTranslation} from "react-i18next";
import {ImageGallery} from "../../components/content/ImageGallery";
import {BUTTON_STYLE, StyledButton1} from "../../components/button/Buttons";
import homeCasino from "../../../resources/images/home-casino.png";
import homeDungeons from "../../../resources/images/home-dungeons.png";
import homeFurniture from "../../../resources/images/home-furniture.png";
import homeHats from "../../../resources/images/home-hats.png";
import homeShops from "../../../resources/images/home-shops.png";
import homeKits from "../../../resources/images/home-kits.png";
import homeRanks from "../../../resources/images/home-ranks.png";
import homeChests from "../../../resources/images/home-chests.png";
import homeBattlepass from "../../../resources/images/home-battlepass.png";
import {useNavigate} from "react-router-dom";
import {DISCORD_INVITE_LINK, PLAY_SERVER_LINK, ROUTER_WIKI} from "../../../global/Constants";
import {AnimatedElementsOpacity} from "../../components/animation/AnimatedElementsOpacity";

const WhatWeGotImages: string[] = [homeCasino, homeDungeons, homeFurniture, homeHats, homeShops, homeKits, homeRanks, homeChests, homeBattlepass];
const WhatWeGotContents = (): React.ReactNode[] => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return [
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("casino")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("casino-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("dungeons")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("dungeons-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("furniture")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("furniture-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("hats")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("hats-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("shops")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("shops-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("kits")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("kits-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("ranks")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("ranks-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("chests")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("chests-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
        <div className={"home-what-we-got-container"}>
            <div className={"home-what-we-got-container-content"}>
                <div className={"home-what-we-got-container-title"}>
                    {t("and-more")}
                </div>
                <div className={"home-what-we-got-container-explanation"}>
                    {t("and-more-home")}
                </div>
                <StyledButton1 style={BUTTON_STYLE.STYLE_3} classes={"override-profile-button"}
                               onClick={() => navigate(`/${ROUTER_WIKI}`)}>
                            <span className={"username-span"}>
                                {t("more")}
                            </span>
                </StyledButton1>
            </div>
        </div>,
    ]
}

export const Home = () => {
    const {t} = useTranslation();

    return <React.Fragment>
        {/*<div className={"home-welcome-image"} style={{backgroundImage: `url(${homeWelcome})`}}>
            <div className={'home-welcome-image-title big-title no-margin'}>
                <div>
                    {t("welcome_to_mc_baltics")}
                </div>
            </div>
        </div>*/}
        <AnimatedElementsOpacity>
        <div className={'text-content home-view'}>
            <AnimatedElementsOpacity>
                <div className={'title no-margin'}>
                    <span className="emoji emoji-join-mc" role="img" aria-label="monkey"></span>
                    {t("home_1")}:
                </div>
                <div className={'text'}>
                    {t("home_1_1")}
                </div>
            </AnimatedElementsOpacity>
            <AnimatedElementsOpacity>
                <div className={'title'}>
                    <span className="emoji emoji-dynamic-economy" role="img" aria-label="monkey"></span>
                    {t("home_2")}:
                </div>
                <div className={'text'}>
                    {t("home_2_1")}
                </div>
            </AnimatedElementsOpacity>
            <AnimatedElementsOpacity>
                <div className={'title'}>
                    <span className="emoji emoji-hardcode" role="img" aria-label="monkey"></span>
                    {t("home_3")}:
                </div>
                <div className={'text'}>
                    {t("home_3_1")}
                </div>
            </AnimatedElementsOpacity>
            <AnimatedElementsOpacity>
                <div className={'title'}>
                    <span className="emoji emoji-thriving-community" role="img" aria-label="monkey"></span>
                    {t("home_4")}:
                </div>
                <div className={'text'}>
                    {t("home_4_1")}
                </div>
            </AnimatedElementsOpacity>
        </div>
            </AnimatedElementsOpacity>
        <AnimatedElementsOpacity>
            <div className={"background-title"}>
                {t("what_we_got")}:
            </div>
            <div style={{position: "relative", marginBottom: "30px"}}>
                <ImageGallery images={WhatWeGotImages} contents={WhatWeGotContents()}/>
            </div>
        </AnimatedElementsOpacity>
        <AnimatedElementsOpacity>
            <div className={"background-title"}>
                {t("join")}:
            </div>
            <div className={"text-content"}>
                <div className={'big-title'}>
                    <span className="emoji emoji-join-mc" role="img" aria-label="monkey"></span>
                    {t("about_9")}:
                </div>
                <div className={'title'}>{t("about_10")}: {PLAY_SERVER_LINK}</div>

                <div className={'big-title margin-top'}>
                    <span className="emoji emoji-join-dc" role="img" aria-label="monkey"></span>
                    {t("about_11")}:
                </div>
                <div className={'title'}>{t("about_12")}: <a href={DISCORD_INVITE_LINK}>{DISCORD_INVITE_LINK}</a></div>
            </div>
        </AnimatedElementsOpacity>
    </React.Fragment>
}