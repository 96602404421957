import {ROUTER_HOME, ROUTER_LOGIN} from "./Constants";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

export const FormatDateFromISOString = (dateString: string): string => {
    const dateComponents = dateString.split('T')[0].split('-');
    const year = dateComponents[0];
    const month = dateComponents[1];
    const day = dateComponents[2];

    return `${year}-${month}-${day}`;
}

export const IsFilled = (text: string | undefined) => {
    return text === undefined || text;
}

export const FormatNumber = (number: number): string => {
    const suffixes: string[] = ["", "k", "M", "B", "T"];
    const suffixNum: number = Math.floor(("" + number).length / 3);
    let shortValue: number = parseFloat((suffixNum !== 0 ? (number / Math.pow(1000, suffixNum)) : number).toPrecision(2));
    if (shortValue % 1 !== 0) {
        shortValue = parseFloat(shortValue.toFixed(1));
    }
    return shortValue + suffixes[suffixNum];
}

const ROUTES_TO_TOP =[`/${ROUTER_HOME}`,`/${ROUTER_LOGIN}`]

export const ScrollToTopOnNavigate = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        let element;
        if (ROUTES_TO_TOP.includes(pathname.toLowerCase())){
            element = document.getElementById('navigater-main');
        }
        else{
            element = document.getElementById('navigater');
        }
        if (element) {
            element.scrollIntoView();
        } else {
            window.scrollTo(0, 200);
        }
    }, [pathname]);

    return null;
}

export const getPrice = (price: number, multiplier: number = 1) => {
    var finalPrice = multiplier * price;
    if (price === finalPrice){
        return <div className={"shop-category-price"}>{getPriceFromCentsToEuros(price)} EUR</div>
    }
    else{
        return <div className={"shop-category-price-container"}>
            <div className={"shop-category-price-discounted"}>{getPriceFromCentsToEuros(finalPrice)} EUR</div>
            <div className={"shop-category-price shop-category-price-crossed"}>{getPriceFromCentsToEuros(price)} EUR</div>
        </div>
    }
}

const getPriceFromCentsToEuros =(price : number) => (price/100).toFixed(2);